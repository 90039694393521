import { GetNetworkAndTokensQuery } from 'client';
import { v5 } from 'uuid';
import { Network, Token, db, TokenBalance } from './db';
import dayjs from 'dayjs';
import { ERC20Token__factory, getMagicProvider, getProvider, NETWORK } from 'chains';
import { find, compact } from 'lodash';

const WALLET_NAMESPACE = 'd2f11a1a-f5fd-4eee-a6bd-8bff0d28902d';

export const generatorAccountId = (userId: string, networkId: string) => {
  return v5(`${userId}-${networkId}`, WALLET_NAMESPACE);
};

export const generatorTokenBalanceId = (accountId: string, tokenId: string) => {
  return v5(`${accountId}-${tokenId}`, WALLET_NAMESPACE);
};

export const syncToDb = async (data: GetNetworkAndTokensQuery) => {
  console.log('Call syncToDb');
  const networksData = data.network;
  let updatedAtNetwork = 0;
  let updatedAtToken = 0;
  const networks = networksData.map<Network>((i) => {
    const updatedAt = dayjs(i.updatedAt).unix();
    if (updatedAt > updatedAtNetwork) {
      updatedAtNetwork = updatedAt;
    }
    return {
      chainId: i.chainId,
      currencyId: i.currencyId,
      name: i.name,
      id: i.id,
      isTestnet: i.isTestnet,
      updatedAt: updatedAt,
    };
  });
  const tokens: Token[] = [];
  networksData.map((i) => {
    const tmp = i.tokens.map<Token>((t) => {
      const updatedAt = dayjs(t.updatedAt).unix();
      if (updatedAt > updatedAtToken) {
        updatedAtToken = updatedAt;
      }
      return {
        id: t.id,
        decimals: t.decimals,
        name: t.currency.name,
        networkId: t.networkId,
        currencyId: t.currencyId,
        updatedAt: dayjs(t.updatedAt).unix(),
      };
    });
    tokens.push(...tmp);
  });

  const lastNetwork = await db.networks.orderBy('updatedAt').last();
  const lastToken = await db.tokens.orderBy('updatedAt').last();

  if (!lastNetwork || updatedAtNetwork > lastNetwork.updatedAt) {
    await db.networks.bulkPut(
      networks.filter((i) => i.updatedAt > (lastNetwork ? lastNetwork.updatedAt : 0))
    );
    console.log('Sync Networks');
  }

  if (!lastToken || updatedAtToken > lastToken.updatedAt) {
    await db.tokens.bulkPut(
      tokens.filter((i) => i.updatedAt > (lastToken ? lastToken.updatedAt : 0))
    );
    console.log('Sync Tokens');
  }
};

export const syncAccount = async (userId: string) => {
  console.log('syncAccount', userId);
  const networks = await db.networks.toArray();
  const length = networks.length;
  for (let i = 0; i < length; i++) {
    const network = networks[i];
    const accountId = generatorAccountId(userId, network.id!);
    const count = await db.accounts.where('id').equals(accountId).count();
    if (count > 0) {
      continue;
    }
    const provider = getMagicProvider(network.id as NETWORK);
    const signer = provider?.getSigner();
    const address = await signer?.getAddress();
    if (address) {
      await db.accounts.put({
        id: accountId,
        userId: userId,
        balance: '0',
        networkId: network.id!,
        publicAddress: address,
        updatedAt: dayjs().unix(),
        none: 0,
      });
      console.log('Create account for ', network.id);
    }
  }
};

export const syncFromChains = async (userId: string) => {
  console.log('syncAll', userId);
  const accounts = await db.accounts.where('userId').equals(userId).toArray();

  // Check token balance first
  const tokens = await db.tokens.toArray();
  const tokenBalancePromisees = tokens.map(async (t) => {
    try {
      const provider = getProvider(t.networkId as NETWORK);
      const tokenContract = ERC20Token__factory.connect(t.id!, provider!);
      const account = accounts.find((a) => a.networkId === t.networkId);
      if (account) {
        const balance = await tokenContract.balanceOf(account?.publicAddress!);
        return {
          tokenId: t.id!,
          balance: balance.toString(),
        };
      }
    } catch (error) {
      console.error(error);
    }
    return undefined;
  });

  const tokenBalanceValues = await Promise.all(tokenBalancePromisees);
  const lastTokenBalances = await db.tokenBalances.toArray();

  const tokenBalances = tokens.map<TokenBalance>((i, index) => {
    const accountId = generatorAccountId(userId, i.networkId);
    const id = generatorTokenBalanceId(accountId, i.id!);
    const lastBalance = find(lastTokenBalances, (l) => l.id === id)?.balance || '0';
    const balance = find(tokenBalanceValues, (l) => l?.tokenId === i.id!)?.balance;
    return {
      id: id,
      userId: userId,
      tokenId: i.id!,
      balance: balance ? balance : lastBalance,
    };
  });

  await db.tokenBalances.bulkPut(tokenBalances);

  const accountPromisees = accounts.map(async (a) => {
    try {
      const provider = getProvider(a.networkId as NETWORK);
      const balance = await provider?.getBalance(a.publicAddress);
      return {
        ...a,
        balance: balance ? balance.toString() : a.balance,
      };
    } catch (error) {
      console.error(error);
    }
    return undefined;
  });

  const accountValues = await Promise.all(accountPromisees);
  const cleanAccountValues = compact(accountValues);

  await db.accounts.bulkPut(cleanAccountValues);
};
