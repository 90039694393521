import {
  Avatar,
  Flex,
  HStack,
  Text,
  Icon,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Spinner,
} from '@chakra-ui/react';
import { useGetUserByIdQuery, UserFragment } from 'client';
import { useAuth } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { ColorMix, TextStyle } from 'theme/types';
import { getUserById } from '../../../services/Strapi.services';

interface IData {
  user: UserFragment | null;
  strapiUserId?: number | null;
}

const UserProfile = () => {
  const { userId, logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IData>({ user: null, strapiUserId: null });

  useEffect(() => {
    const getUserReq = async () => {
      let req = await getUserById(userId || '');
      setData({ user: req.data[0].attributes, strapiUserId: req.data[0].id });
    };

    getUserReq();
  }, [userId]);

  const profileName = data?.user?.firstName || 'unknown';
  const email = data?.user?.email || '';

  const btnLogout = useCallback(() => {
    setLoading(true);
    logout()
      .then()
      .finally(() => {
        setLoading(false);
      });
  }, [logout]);

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton>
            <HStack spacing="2" pl="3">
              <Avatar boxSize={10} name={profileName} />
              <Flex alignItems="flex-start" direction="column" fontWeight="medium">
                <Text
                  textStyle={TextStyle.ParagraphSmall}
                  fontWeight="medium"
                  maxW={'28'}
                  noOfLines={1}>
                  {`${profileName}`}
                </Text>
                <Text
                  color={'gray.400'}
                  textStyle={TextStyle.ParagraphXSmall}
                  textOverflow={'ellipsis'}
                  maxW={'28'}
                  noOfLines={1}>
                  {email}
                </Text>
              </Flex>
              <Icon as={HiOutlineChevronDown} w="24px" h="24px" color={ColorMix.textSub} />
            </HStack>
          </MenuButton>

          <MenuList>
            <MenuItem onClick={btnLogout} icon={loading ? <Spinner /> : undefined}>
              Logout
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default UserProfile;
