import { useCallback, useEffect, memo } from 'react';
import { Box, Spinner, VStack, Text, Button } from '@chakra-ui/react';
import Logo from 'components/Logo';
import * as TokenStorage from 'utils/TokenStorage';
import { useLoginMutation } from 'client';
import { useAuth, useMagic } from 'hooks';
import { NETWORK } from 'chains';

const Splash = () => {
  const { setToken, setUserMetadata, setIsInitializing } = useAuth();
  const [{ error, fetching }, loginWithServer] = useLoginMutation();
  const { magic } = useMagic(NETWORK.ethereum);

  useEffect(() => {
    (async () => {
      const token = TokenStorage.getToken();
      const isLogin = await magic.user.isLoggedIn();
      console.log('magic isLogin', isLogin);
      if (isLogin) {
        const userMetadata = await magic.user.getMetadata();
        setUserMetadata(userMetadata);
        if (token) {
          console.log('token already exists: ', token);
          setToken(token);
          setIsInitializing(false);
        } else {
          const idToken = await magic.user.getIdToken();
          const result = await loginWithServer(
            { didToken: idToken },
            { requestPolicy: 'network-only' }
          );
          setToken(idToken);
          setIsInitializing(false);
        }
      } else {
        setToken(undefined);
        setUserMetadata(undefined);
        setIsInitializing(false);
      }
    })();
  }, [loginWithServer, magic.user, setIsInitializing, setToken, setUserMetadata]);

  const retry = useCallback(() => {
    (async () => {
      const idToken = await magic.user.getIdToken();
      const result = await loginWithServer(
        { didToken: idToken },
        { requestPolicy: 'network-only' }
      );
      setToken(idToken);
      setIsInitializing(false);
    })();
  }, [loginWithServer, magic.user, setIsInitializing, setToken]);
  return (
    <Box minH="100vh" display={'flex'} justifyContent="center" alignItems={'center'}>
      <VStack spacing={'6'}>
        <Logo height="8" />
        <Spinner size={'lg'} />
        {error && (
          <VStack spacing={'10'}>
            <Text>
              {error?.networkError ? 'Can not connect to server' : 'The server could not execute'}
            </Text>
            <Button isLoading={fetching} onClick={retry}>
              Retry
            </Button>
          </VStack>
        )}
      </VStack>
    </Box>
  );
};

export default memo(Splash);
