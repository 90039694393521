import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  json: any;
  jsonb: any;
  numeric: number;
  timestamptz: string;
  uuid: string;
};

export type ApproveInvoiceInput = {
  invoiceId: Scalars['String'];
  isApproved: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to filter rows from the table "Contact". All fields are combined with a logical 'AND'. */
export type ContactBoolExp = {
  _and?: InputMaybe<Array<ContactBoolExp>>;
  _not?: InputMaybe<ContactBoolExp>;
  _or?: InputMaybe<Array<ContactBoolExp>>;
  addressFirst?: InputMaybe<StringComparisonExp>;
  addressSecond?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  companyName?: InputMaybe<StringComparisonExp>;
  countryId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  department?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  fullName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  invoices?: InputMaybe<InvoiceBoolExp>;
  jobTitle?: InputMaybe<StringComparisonExp>;
  ownerId?: InputMaybe<StringComparisonExp>;
  phoneNumber?: InputMaybe<StringComparisonExp>;
  postCode?: InputMaybe<NumericComparisonExp>;
  type?: InputMaybe<ContactTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "Contact" */
export enum ContactConstraint {
  /** unique or primary key constraint on columns "type", "ownerId", "email" */
  ContactOwnerIdEmailTypeKey = 'Contact_ownerId_email_type_key',
  /** unique or primary key constraint on columns "id" */
  ContactPkey = 'Contact_pkey',
}

/** input type for incrementing numeric columns in table "Contact" */
export type ContactIncInput = {
  postCode?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Contact" */
export type ContactInsertInput = {
  addressFirst?: InputMaybe<Scalars['String']>;
  addressSecond?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['numeric']>;
  type?: InputMaybe<ContactTypeEnum>;
};

/** on_conflict condition type for table "Contact" */
export type ContactOnConflict = {
  constraint: ContactConstraint;
  update_columns?: Array<ContactUpdateColumn>;
  where?: InputMaybe<ContactBoolExp>;
};

/** Ordering options when selecting data from "Contact". */
export type ContactOrderBy = {
  addressFirst?: InputMaybe<OrderBy>;
  addressSecond?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  companyName?: InputMaybe<OrderBy>;
  countryId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  department?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoicesAggregate?: InputMaybe<InvoiceAggregateOrderBy>;
  jobTitle?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  postCode?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: Contact */
export type ContactPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "Contact" */
export enum ContactSelectColumn {
  /** column name */
  AddressFirst = 'addressFirst',
  /** column name */
  AddressSecond = 'addressSecond',
  /** column name */
  City = 'city',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Department = 'department',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  JobTitle = 'jobTitle',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PostCode = 'postCode',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "Contact" */
export type ContactSetInput = {
  addressFirst?: InputMaybe<Scalars['String']>;
  addressSecond?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['numeric']>;
};

/** Streaming cursor of the table "Contact" */
export type ContactStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ContactStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContactStreamCursorValueInput = {
  addressFirst?: InputMaybe<Scalars['String']>;
  addressSecond?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  department?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['numeric']>;
  type?: InputMaybe<ContactTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "ContactType". All fields are combined with a logical 'AND'. */
export type ContactTypeBoolExp = {
  _and?: InputMaybe<Array<ContactTypeBoolExp>>;
  _not?: InputMaybe<ContactTypeBoolExp>;
  _or?: InputMaybe<Array<ContactTypeBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
};

export enum ContactTypeEnum {
  Customer = 'CUSTOMER',
  Employe = 'EMPLOYE',
}

/** Boolean expression to compare columns of type "ContactTypeEnum". All fields are combined with logical 'AND'. */
export type ContactTypeEnumComparisonExp = {
  _eq?: InputMaybe<ContactTypeEnum>;
  _in?: InputMaybe<Array<ContactTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ContactTypeEnum>;
  _nin?: InputMaybe<Array<ContactTypeEnum>>;
};

/** Ordering options when selecting data from "ContactType". */
export type ContactTypeOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "ContactType" */
export enum ContactTypeSelectColumn {
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "ContactType" */
export type ContactTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ContactTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContactTypeStreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "Contact" */
export enum ContactUpdateColumn {
  /** column name */
  AddressFirst = 'addressFirst',
  /** column name */
  AddressSecond = 'addressSecond',
  /** column name */
  City = 'city',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  Department = 'department',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'fullName',
  /** column name */
  JobTitle = 'jobTitle',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PostCode = 'postCode',
}

export type ContactUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ContactIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContactSetInput>;
  where: ContactBoolExp;
};

/** Boolean expression to filter rows from the table "Country". All fields are combined with a logical 'AND'. */
export type CountryBoolExp = {
  _and?: InputMaybe<Array<CountryBoolExp>>;
  _not?: InputMaybe<CountryBoolExp>;
  _or?: InputMaybe<Array<CountryBoolExp>>;
  dialCode?: InputMaybe<StringComparisonExp>;
  emoji?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "Country". */
export type CountryOrderBy = {
  dialCode?: InputMaybe<OrderBy>;
  emoji?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "Country" */
export enum CountrySelectColumn {
  /** column name */
  DialCode = 'dialCode',
  /** column name */
  Emoji = 'emoji',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "Country" */
export type CountryStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CountryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CountryStreamCursorValueInput = {
  dialCode?: InputMaybe<Scalars['String']>;
  emoji?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "Currency". All fields are combined with a logical 'AND'. */
export type CurrencyBoolExp = {
  _and?: InputMaybe<Array<CurrencyBoolExp>>;
  _not?: InputMaybe<CurrencyBoolExp>;
  _or?: InputMaybe<Array<CurrencyBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
  isFiat?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  priceUSD?: InputMaybe<NumericComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** Ordering options when selecting data from "Currency". */
export type CurrencyOrderBy = {
  id?: InputMaybe<OrderBy>;
  isFiat?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  priceUSD?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "Currency" */
export enum CurrencySelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  IsFiat = 'isFiat',
  /** column name */
  Name = 'name',
  /** column name */
  PriceUsd = 'priceUSD',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** Streaming cursor of the table "Currency" */
export type CurrencyStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CurrencyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CurrencyStreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
  isFiat?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  priceUSD?: InputMaybe<Scalars['numeric']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** Boolean expression to filter rows from the table "Document". All fields are combined with a logical 'AND'. */
export type DocumentBoolExp = {
  _and?: InputMaybe<Array<DocumentBoolExp>>;
  _not?: InputMaybe<DocumentBoolExp>;
  _or?: InputMaybe<Array<DocumentBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  filePath?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  ownerId?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<DocumentTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  verifyById?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "Document" */
export enum DocumentConstraint {
  /** unique or primary key constraint on columns "type", "ownerId" */
  DocumentOwnerIdTypeKey = 'Document_ownerId_type_key',
  /** unique or primary key constraint on columns "id" */
  DocumentPkey = 'Document_pkey',
}

/** input type for inserting data into table "Document" */
export type DocumentInsertInput = {
  filePath?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DocumentTypeEnum>;
};

/** on_conflict condition type for table "Document" */
export type DocumentOnConflict = {
  constraint: DocumentConstraint;
  update_columns?: Array<DocumentUpdateColumn>;
  where?: InputMaybe<DocumentBoolExp>;
};

/** Ordering options when selecting data from "Document". */
export type DocumentOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  filePath?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  verifyById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: Document */
export type DocumentPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "Document" */
export enum DocumentSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FilePath = 'filePath',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VerifyById = 'verifyById',
}

/** input type for updating data in table "Document" */
export type DocumentSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  filePath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  ownerId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DocumentTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  verifyById?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "DocumentStatus". All fields are combined with a logical 'AND'. */
export type DocumentStatusBoolExp = {
  _and?: InputMaybe<Array<DocumentStatusBoolExp>>;
  _not?: InputMaybe<DocumentStatusBoolExp>;
  _or?: InputMaybe<Array<DocumentStatusBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "DocumentStatus". */
export type DocumentStatusOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "DocumentStatus" */
export enum DocumentStatusSelectColumn {
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "DocumentStatus" */
export type DocumentStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DocumentStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentStatusStreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "Document" */
export type DocumentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DocumentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  filePath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  ownerId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DocumentTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  verifyById?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "DocumentType". All fields are combined with a logical 'AND'. */
export type DocumentTypeBoolExp = {
  _and?: InputMaybe<Array<DocumentTypeBoolExp>>;
  _not?: InputMaybe<DocumentTypeBoolExp>;
  _or?: InputMaybe<Array<DocumentTypeBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
};

export enum DocumentTypeEnum {
  GovernmentId = 'GOVERNMENT_ID',
  RegisteredAddress = 'REGISTERED_ADDRESS',
  TaxRegistration = 'TAX_REGISTRATION',
  VideoVerification = 'VIDEO_VERIFICATION',
}

/** Boolean expression to compare columns of type "DocumentTypeEnum". All fields are combined with logical 'AND'. */
export type DocumentTypeEnumComparisonExp = {
  _eq?: InputMaybe<DocumentTypeEnum>;
  _in?: InputMaybe<Array<DocumentTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<DocumentTypeEnum>;
  _nin?: InputMaybe<Array<DocumentTypeEnum>>;
};

/** Ordering options when selecting data from "DocumentType". */
export type DocumentTypeOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "DocumentType" */
export enum DocumentTypeSelectColumn {
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "DocumentType" */
export type DocumentTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DocumentTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentTypeStreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "Document" */
export enum DocumentUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FilePath = 'filePath',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VerifyById = 'verifyById',
}

export type DocumentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentSetInput>;
  where: DocumentBoolExp;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** order by aggregate values of table "InvoiceAccess" */
export type InvoiceAccessAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InvoiceAccessMaxOrderBy>;
  min?: InputMaybe<InvoiceAccessMinOrderBy>;
};

/** Boolean expression to filter rows from the table "InvoiceAccess". All fields are combined with a logical 'AND'. */
export type InvoiceAccessBoolExp = {
  _and?: InputMaybe<Array<InvoiceAccessBoolExp>>;
  _not?: InputMaybe<InvoiceAccessBoolExp>;
  _or?: InputMaybe<Array<InvoiceAccessBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<StringComparisonExp>;
  isPayer?: InputMaybe<BooleanComparisonExp>;
  ownerId?: InputMaybe<StringComparisonExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "InvoiceAccess" */
export type InvoiceAccessMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "InvoiceAccess" */
export type InvoiceAccessMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "InvoiceAccess". */
export type InvoiceAccessOrderBy = {
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  isPayer?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "InvoiceAccess" */
export enum InvoiceAccessSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  IsPayer = 'isPayer',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  UserId = 'userId',
}

/** Streaming cursor of the table "InvoiceAccess" */
export type InvoiceAccessStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: InvoiceAccessStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceAccessStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  isPayer?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "Invoice" */
export type InvoiceAggregateOrderBy = {
  avg?: InputMaybe<InvoiceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InvoiceMaxOrderBy>;
  min?: InputMaybe<InvoiceMinOrderBy>;
  stddev?: InputMaybe<InvoiceStddevOrderBy>;
  stddev_pop?: InputMaybe<InvoiceStddevPopOrderBy>;
  stddev_samp?: InputMaybe<InvoiceStddevSampOrderBy>;
  sum?: InputMaybe<InvoiceSumOrderBy>;
  var_pop?: InputMaybe<InvoiceVarPopOrderBy>;
  var_samp?: InputMaybe<InvoiceVarSampOrderBy>;
  variance?: InputMaybe<InvoiceVarianceOrderBy>;
};

/** order by avg() on columns of table "Invoice" */
export type InvoiceAvgOrderBy = {
  discount?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "Invoice". All fields are combined with a logical 'AND'. */
export type InvoiceBoolExp = {
  _and?: InputMaybe<Array<InvoiceBoolExp>>;
  _not?: InputMaybe<InvoiceBoolExp>;
  _or?: InputMaybe<Array<InvoiceBoolExp>>;
  accesses?: InputMaybe<InvoiceAccessBoolExp>;
  confirmAt?: InputMaybe<TimestamptzComparisonExp>;
  contact?: InputMaybe<ContactBoolExp>;
  contractId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  customer?: InputMaybe<UserBoolExp>;
  customerId?: InputMaybe<StringComparisonExp>;
  discount?: InputMaybe<NumericComparisonExp>;
  dueAt?: InputMaybe<TimestamptzComparisonExp>;
  extras?: InputMaybe<InvoiceExtraBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  isApproved?: InputMaybe<BooleanComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  isDrafted?: InputMaybe<BooleanComparisonExp>;
  isExpired?: InputMaybe<BooleanComparisonExp>;
  isPaid?: InputMaybe<BooleanComparisonExp>;
  items?: InputMaybe<InvoiceItemBoolExp>;
  note?: InputMaybe<StringComparisonExp>;
  owner?: InputMaybe<UserBoolExp>;
  ownerId?: InputMaybe<StringComparisonExp>;
  payment?: InputMaybe<PaymentBoolExp>;
  paymentId?: InputMaybe<StringComparisonExp>;
  tax?: InputMaybe<NumericComparisonExp>;
  token?: InputMaybe<TokenBoolExp>;
  tokenId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  vatId?: InputMaybe<StringComparisonExp>;
};

/** order by aggregate values of table "InvoiceExtra" */
export type InvoiceExtraAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InvoiceExtraMaxOrderBy>;
  min?: InputMaybe<InvoiceExtraMinOrderBy>;
};

/** Boolean expression to filter rows from the table "InvoiceExtra". All fields are combined with a logical 'AND'. */
export type InvoiceExtraBoolExp = {
  _and?: InputMaybe<Array<InvoiceExtraBoolExp>>;
  _not?: InputMaybe<InvoiceExtraBoolExp>;
  _or?: InputMaybe<Array<InvoiceExtraBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "InvoiceExtra" */
export enum InvoiceExtraConstraint {
  /** unique or primary key constraint on columns "name", "invoiceId" */
  InvoiceExtraInvoiceIdNameKey = 'InvoiceExtra_invoiceId_name_key',
  /** unique or primary key constraint on columns "id" */
  InvoiceExtraPkey = 'InvoiceExtra_pkey',
}

/** input type for inserting data into table "InvoiceExtra" */
export type InvoiceExtraInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "InvoiceExtra" */
export type InvoiceExtraMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "InvoiceExtra" */
export type InvoiceExtraMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** on_conflict condition type for table "InvoiceExtra" */
export type InvoiceExtraOnConflict = {
  constraint: InvoiceExtraConstraint;
  update_columns?: Array<InvoiceExtraUpdateColumn>;
  where?: InputMaybe<InvoiceExtraBoolExp>;
};

/** Ordering options when selecting data from "InvoiceExtra". */
export type InvoiceExtraOrderBy = {
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: InvoiceExtra */
export type InvoiceExtraPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "InvoiceExtra" */
export enum InvoiceExtraSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "InvoiceExtra" */
export type InvoiceExtraSetInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "InvoiceExtra" */
export type InvoiceExtraStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: InvoiceExtraStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceExtraStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "InvoiceExtra" */
export enum InvoiceExtraUpdateColumn {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value',
}

export type InvoiceExtraUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceExtraSetInput>;
  where: InvoiceExtraBoolExp;
};

/** input type for incrementing numeric columns in table "Invoice" */
export type InvoiceIncInput = {
  discount?: InputMaybe<Scalars['numeric']>;
  tax?: InputMaybe<Scalars['numeric']>;
};

/** order by aggregate values of table "InvoiceItem" */
export type InvoiceItemAggregateOrderBy = {
  avg?: InputMaybe<InvoiceItemAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InvoiceItemMaxOrderBy>;
  min?: InputMaybe<InvoiceItemMinOrderBy>;
  stddev?: InputMaybe<InvoiceItemStddevOrderBy>;
  stddev_pop?: InputMaybe<InvoiceItemStddevPopOrderBy>;
  stddev_samp?: InputMaybe<InvoiceItemStddevSampOrderBy>;
  sum?: InputMaybe<InvoiceItemSumOrderBy>;
  var_pop?: InputMaybe<InvoiceItemVarPopOrderBy>;
  var_samp?: InputMaybe<InvoiceItemVarSampOrderBy>;
  variance?: InputMaybe<InvoiceItemVarianceOrderBy>;
};

/** order by avg() on columns of table "InvoiceItem" */
export type InvoiceItemAvgOrderBy = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "InvoiceItem". All fields are combined with a logical 'AND'. */
export type InvoiceItemBoolExp = {
  _and?: InputMaybe<Array<InvoiceItemBoolExp>>;
  _not?: InputMaybe<InvoiceItemBoolExp>;
  _or?: InputMaybe<Array<InvoiceItemBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  invoiceId?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  quality?: InputMaybe<IntComparisonExp>;
  total?: InputMaybe<NumericComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "InvoiceItem" */
export enum InvoiceItemConstraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceItemPkey = 'InvoiceItem_pkey',
}

/** input type for incrementing numeric columns in table "InvoiceItem" */
export type InvoiceItemIncInput = {
  price?: InputMaybe<Scalars['numeric']>;
  quality?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "InvoiceItem" */
export type InvoiceItemInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  quality?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "InvoiceItem" */
export type InvoiceItemMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "InvoiceItem" */
export type InvoiceItemMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** on_conflict condition type for table "InvoiceItem" */
export type InvoiceItemOnConflict = {
  constraint: InvoiceItemConstraint;
  update_columns?: Array<InvoiceItemUpdateColumn>;
  where?: InputMaybe<InvoiceItemBoolExp>;
};

/** Ordering options when selecting data from "InvoiceItem". */
export type InvoiceItemOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: InvoiceItem */
export type InvoiceItemPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "InvoiceItem" */
export enum InvoiceItemSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Quality = 'quality',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "InvoiceItem" */
export type InvoiceItemSetInput = {
  invoiceId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  quality?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "InvoiceItem" */
export type InvoiceItemStddevOrderBy = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "InvoiceItem" */
export type InvoiceItemStddevPopOrderBy = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "InvoiceItem" */
export type InvoiceItemStddevSampOrderBy = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "InvoiceItem" */
export type InvoiceItemStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: InvoiceItemStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceItemStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  quality?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['numeric']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "InvoiceItem" */
export type InvoiceItemSumOrderBy = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** update columns of table "InvoiceItem" */
export enum InvoiceItemUpdateColumn {
  /** column name */
  InvoiceId = 'invoiceId',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Quality = 'quality',
}

export type InvoiceItemUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InvoiceItemIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceItemSetInput>;
  where: InvoiceItemBoolExp;
};

/** order by var_pop() on columns of table "InvoiceItem" */
export type InvoiceItemVarPopOrderBy = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "InvoiceItem" */
export type InvoiceItemVarSampOrderBy = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "InvoiceItem" */
export type InvoiceItemVarianceOrderBy = {
  price?: InputMaybe<OrderBy>;
  quality?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "Invoice" */
export type InvoiceMaxOrderBy = {
  confirmAt?: InputMaybe<OrderBy>;
  contractId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  customerId?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  dueAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  tokenId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  vatId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "Invoice" */
export type InvoiceMinOrderBy = {
  confirmAt?: InputMaybe<OrderBy>;
  contractId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  customerId?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  dueAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  tokenId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  vatId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "Invoice". */
export type InvoiceOrderBy = {
  accessesAggregate?: InputMaybe<InvoiceAccessAggregateOrderBy>;
  confirmAt?: InputMaybe<OrderBy>;
  contact?: InputMaybe<ContactOrderBy>;
  contractId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  customer?: InputMaybe<UserOrderBy>;
  customerId?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  dueAt?: InputMaybe<OrderBy>;
  extrasAggregate?: InputMaybe<InvoiceExtraAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isApproved?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  isDrafted?: InputMaybe<OrderBy>;
  isExpired?: InputMaybe<OrderBy>;
  isPaid?: InputMaybe<OrderBy>;
  itemsAggregate?: InputMaybe<InvoiceItemAggregateOrderBy>;
  note?: InputMaybe<OrderBy>;
  owner?: InputMaybe<UserOrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  payment?: InputMaybe<PaymentOrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
  token?: InputMaybe<TokenOrderBy>;
  tokenId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  vatId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: Invoice */
export type InvoicePkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "Invoice" */
export enum InvoiceSelectColumn {
  /** column name */
  ConfirmAt = 'confirmAt',
  /** column name */
  ContractId = 'contractId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Discount = 'discount',
  /** column name */
  DueAt = 'dueAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'isApproved',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsDrafted = 'isDrafted',
  /** column name */
  IsPaid = 'isPaid',
  /** column name */
  Note = 'note',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  PaymentId = 'paymentId',
  /** column name */
  Tax = 'tax',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VatId = 'vatId',
}

/** input type for updating data in table "Invoice" */
export type InvoiceSetInput = {
  contractId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  discount?: InputMaybe<Scalars['numeric']>;
  dueAt?: InputMaybe<Scalars['timestamptz']>;
  note?: InputMaybe<Scalars['String']>;
  tax?: InputMaybe<Scalars['numeric']>;
  tokenId?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "Invoice" */
export type InvoiceStddevOrderBy = {
  discount?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "Invoice" */
export type InvoiceStddevPopOrderBy = {
  discount?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "Invoice" */
export type InvoiceStddevSampOrderBy = {
  discount?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "Invoice" */
export type InvoiceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: InvoiceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceStreamCursorValueInput = {
  confirmAt?: InputMaybe<Scalars['timestamptz']>;
  contractId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerId?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['numeric']>;
  dueAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isDrafted?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  paymentId?: InputMaybe<Scalars['String']>;
  tax?: InputMaybe<Scalars['numeric']>;
  tokenId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vatId?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "Invoice" */
export type InvoiceSumOrderBy = {
  discount?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

export type InvoiceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InvoiceIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceSetInput>;
  where: InvoiceBoolExp;
};

/** order by var_pop() on columns of table "Invoice" */
export type InvoiceVarPopOrderBy = {
  discount?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "Invoice" */
export type InvoiceVarSampOrderBy = {
  discount?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "Invoice" */
export type InvoiceVarianceOrderBy = {
  discount?: InputMaybe<OrderBy>;
  tax?: InputMaybe<OrderBy>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _containedIn?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** Boolean expression to filter rows from the table "Network". All fields are combined with a logical 'AND'. */
export type NetworkBoolExp = {
  _and?: InputMaybe<Array<NetworkBoolExp>>;
  _not?: InputMaybe<NetworkBoolExp>;
  _or?: InputMaybe<Array<NetworkBoolExp>>;
  chainId?: InputMaybe<NumericComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrencyBoolExp>;
  currencyId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isLive?: InputMaybe<BooleanComparisonExp>;
  isTestnet?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  tokens?: InputMaybe<TokenBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** Ordering options when selecting data from "Network". */
export type NetworkOrderBy = {
  chainId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrencyOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isLive?: InputMaybe<OrderBy>;
  isTestnet?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  tokensAggregate?: InputMaybe<TokenAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "Network" */
export enum NetworkSelectColumn {
  /** column name */
  ChainId = 'chainId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsLive = 'isLive',
  /** column name */
  IsTestnet = 'isTestnet',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** Streaming cursor of the table "Network" */
export type NetworkStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: NetworkStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type NetworkStreamCursorValueInput = {
  chainId?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isLive?: InputMaybe<Scalars['Boolean']>;
  isTestnet?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'ASC',
  /** in ascending order, nulls first */
  AscNullsFirst = 'ASC_NULLS_FIRST',
  /** in ascending order, nulls last */
  AscNullsLast = 'ASC_NULLS_LAST',
  /** in descending order, nulls first */
  Desc = 'DESC',
  /** in descending order, nulls first */
  DescNullsFirst = 'DESC_NULLS_FIRST',
  /** in descending order, nulls last */
  DescNullsLast = 'DESC_NULLS_LAST',
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type PaymentAppAppendInput = {
  extras?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "PaymentApp". All fields are combined with a logical 'AND'. */
export type PaymentAppBoolExp = {
  _and?: InputMaybe<Array<PaymentAppBoolExp>>;
  _not?: InputMaybe<PaymentAppBoolExp>;
  _or?: InputMaybe<Array<PaymentAppBoolExp>>;
  apiToken?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  extras?: InputMaybe<JsonbComparisonExp>;
  homepage?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isEnabled?: InputMaybe<BooleanComparisonExp>;
  kind?: InputMaybe<PaymentKindEnumComparisonExp>;
  logoPath?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  owner?: InputMaybe<UserBoolExp>;
  ownerId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  whitelistedCurrency?: InputMaybe<StringComparisonExp>;
  whitelistedDomain?: InputMaybe<StringComparisonExp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PaymentAppDeleteAtPathInput = {
  extras?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PaymentAppDeleteElemInput = {
  extras?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PaymentAppDeleteKeyInput = {
  extras?: InputMaybe<Scalars['String']>;
};

export type PaymentAppInput = {
  extras?: InputMaybe<Scalars['jsonb']>;
  homepage?: InputMaybe<Scalars['String']>;
  kind: PaymentKindInput;
  name?: InputMaybe<Scalars['String']>;
};

/** Ordering options when selecting data from "PaymentApp". */
export type PaymentAppOrderBy = {
  apiToken?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  extras?: InputMaybe<OrderBy>;
  homepage?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isEnabled?: InputMaybe<OrderBy>;
  kind?: InputMaybe<OrderBy>;
  logoPath?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  owner?: InputMaybe<UserOrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  whitelistedCurrency?: InputMaybe<OrderBy>;
  whitelistedDomain?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: PaymentApp */
export type PaymentAppPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PaymentAppPrependInput = {
  extras?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "PaymentApp" */
export enum PaymentAppSelectColumn {
  /** column name */
  ApiToken = 'apiToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Extras = 'extras',
  /** column name */
  Homepage = 'homepage',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'isEnabled',
  /** column name */
  Kind = 'kind',
  /** column name */
  LogoPath = 'logoPath',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WhitelistedCurrency = 'whitelistedCurrency',
  /** column name */
  WhitelistedDomain = 'whitelistedDomain',
}

/** input type for updating data in table "PaymentApp" */
export type PaymentAppSetInput = {
  extras?: InputMaybe<Scalars['jsonb']>;
  homepage?: InputMaybe<Scalars['String']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  logoPath?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  whitelistedCurrency?: InputMaybe<Scalars['String']>;
  whitelistedDomain?: InputMaybe<Scalars['String']>;
  whitelistedNetwork?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "PaymentApp" */
export type PaymentAppStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentAppStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentAppStreamCursorValueInput = {
  apiToken?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  extras?: InputMaybe<Scalars['jsonb']>;
  homepage?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<PaymentKindEnum>;
  logoPath?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  whitelistedCurrency?: InputMaybe<Scalars['String']>;
  whitelistedDomain?: InputMaybe<Scalars['String']>;
};

export type PaymentAppUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PaymentAppAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<PaymentAppDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<PaymentAppDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<PaymentAppDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PaymentAppPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentAppSetInput>;
  where: PaymentAppBoolExp;
};

/** Boolean expression to filter rows from the table "Payment". All fields are combined with a logical 'AND'. */
export type PaymentBoolExp = {
  _and?: InputMaybe<Array<PaymentBoolExp>>;
  _not?: InputMaybe<PaymentBoolExp>;
  _or?: InputMaybe<Array<PaymentBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  app?: InputMaybe<PaymentAppBoolExp>;
  appId?: InputMaybe<UuidComparisonExp>;
  fiatAmount?: InputMaybe<NumericComparisonExp>;
  fiatCurrencyId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  invoice?: InputMaybe<InvoiceBoolExp>;
  isFiatBased?: InputMaybe<BooleanComparisonExp>;
  method?: InputMaybe<PaymentMethodEnumComparisonExp>;
  orderDescription?: InputMaybe<StringComparisonExp>;
  orderId?: InputMaybe<StringComparisonExp>;
  ownerId?: InputMaybe<StringComparisonExp>;
  streamFrequency?: InputMaybe<StreamFrequencyEnumComparisonExp>;
  token?: InputMaybe<TokenBoolExp>;
  tokenId?: InputMaybe<StringComparisonExp>;
  transactionId?: InputMaybe<StringComparisonExp>;
  txId?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to filter rows from the table "PaymentKind". All fields are combined with a logical 'AND'. */
export type PaymentKindBoolExp = {
  _and?: InputMaybe<Array<PaymentKindBoolExp>>;
  _not?: InputMaybe<PaymentKindBoolExp>;
  _or?: InputMaybe<Array<PaymentKindBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
};

export enum PaymentKindEnum {
  Invoice = 'INVOICE',
  Opencart = 'OPENCART',
  Shopify = 'SHOPIFY',
  Woocommerce = 'WOOCOMMERCE',
}

/** Boolean expression to compare columns of type "PaymentKindEnum". All fields are combined with logical 'AND'. */
export type PaymentKindEnumComparisonExp = {
  _eq?: InputMaybe<PaymentKindEnum>;
  _in?: InputMaybe<Array<PaymentKindEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<PaymentKindEnum>;
  _nin?: InputMaybe<Array<PaymentKindEnum>>;
};

export enum PaymentKindInput {
  Invoice = 'INVOICE',
  Opencart = 'OPENCART',
  Shopify = 'SHOPIFY',
  Woocommerce = 'WOOCOMMERCE',
}

/** Ordering options when selecting data from "PaymentKind". */
export type PaymentKindOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "PaymentKind" */
export enum PaymentKindSelectColumn {
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "PaymentKind" */
export type PaymentKindStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentKindStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentKindStreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "PaymentMethod". All fields are combined with a logical 'AND'. */
export type PaymentMethodBoolExp = {
  _and?: InputMaybe<Array<PaymentMethodBoolExp>>;
  _not?: InputMaybe<PaymentMethodBoolExp>;
  _or?: InputMaybe<Array<PaymentMethodBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
};

export enum PaymentMethodEnum {
  Now = 'NOW',
  Stream = 'STREAM',
}

/** Boolean expression to compare columns of type "PaymentMethodEnum". All fields are combined with logical 'AND'. */
export type PaymentMethodEnumComparisonExp = {
  _eq?: InputMaybe<PaymentMethodEnum>;
  _in?: InputMaybe<Array<PaymentMethodEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<PaymentMethodEnum>;
  _nin?: InputMaybe<Array<PaymentMethodEnum>>;
};

/** Ordering options when selecting data from "PaymentMethod". */
export type PaymentMethodOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "PaymentMethod" */
export enum PaymentMethodSelectColumn {
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "PaymentMethod" */
export type PaymentMethodStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentMethodStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentMethodStreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Payment". */
export type PaymentOrderBy = {
  amount?: InputMaybe<OrderBy>;
  app?: InputMaybe<PaymentAppOrderBy>;
  appId?: InputMaybe<OrderBy>;
  fiatAmount?: InputMaybe<OrderBy>;
  fiatCurrencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoice?: InputMaybe<InvoiceOrderBy>;
  isFiatBased?: InputMaybe<OrderBy>;
  method?: InputMaybe<OrderBy>;
  orderDescription?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  streamFrequency?: InputMaybe<OrderBy>;
  token?: InputMaybe<TokenOrderBy>;
  tokenId?: InputMaybe<OrderBy>;
  transactionId?: InputMaybe<OrderBy>;
  txId?: InputMaybe<OrderBy>;
};

/** select columns of table "Payment" */
export enum PaymentSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  AppId = 'appId',
  /** column name */
  FiatAmount = 'fiatAmount',
  /** column name */
  FiatCurrencyId = 'fiatCurrencyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsFiatBased = 'isFiatBased',
  /** column name */
  Method = 'method',
  /** column name */
  OrderDescription = 'orderDescription',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  StreamFrequency = 'streamFrequency',
  /** column name */
  TokenId = 'tokenId',
  /** column name */
  TransactionId = 'transactionId',
  /** column name */
  TxId = 'txId',
}

/** Streaming cursor of the table "Payment" */
export type PaymentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentStreamCursorValueInput = {
  amount?: InputMaybe<Scalars['numeric']>;
  appId?: InputMaybe<Scalars['uuid']>;
  fiatAmount?: InputMaybe<Scalars['numeric']>;
  fiatCurrencyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isFiatBased?: InputMaybe<Scalars['Boolean']>;
  method?: InputMaybe<PaymentMethodEnum>;
  orderDescription?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  streamFrequency?: InputMaybe<StreamFrequencyEnum>;
  tokenId?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
  txId?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "PaymentTransaction". All fields are combined with a logical 'AND'. */
export type PaymentTransactionBoolExp = {
  _and?: InputMaybe<Array<PaymentTransactionBoolExp>>;
  _not?: InputMaybe<PaymentTransactionBoolExp>;
  _or?: InputMaybe<Array<PaymentTransactionBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  blockId?: InputMaybe<NumericComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  feeAmount?: InputMaybe<NumericComparisonExp>;
  gasLimit?: InputMaybe<NumericComparisonExp>;
  gasPrice?: InputMaybe<NumericComparisonExp>;
  gasUsed?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  payment?: InputMaybe<PaymentBoolExp>;
  paymentId?: InputMaybe<StringComparisonExp>;
  recipient?: InputMaybe<StringComparisonExp>;
  recipientAmount?: InputMaybe<NumericComparisonExp>;
  sender?: InputMaybe<StringComparisonExp>;
  timestamp?: InputMaybe<TimestamptzComparisonExp>;
  token?: InputMaybe<TokenBoolExp>;
  tokenAddress?: InputMaybe<StringComparisonExp>;
  txId?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "PaymentTransaction". */
export type PaymentTransactionOrderBy = {
  amount?: InputMaybe<OrderBy>;
  blockId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  feeAmount?: InputMaybe<OrderBy>;
  gasLimit?: InputMaybe<OrderBy>;
  gasPrice?: InputMaybe<OrderBy>;
  gasUsed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  payment?: InputMaybe<PaymentOrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  recipient?: InputMaybe<OrderBy>;
  recipientAmount?: InputMaybe<OrderBy>;
  sender?: InputMaybe<OrderBy>;
  timestamp?: InputMaybe<OrderBy>;
  token?: InputMaybe<TokenOrderBy>;
  tokenAddress?: InputMaybe<OrderBy>;
  txId?: InputMaybe<OrderBy>;
};

/** select columns of table "PaymentTransaction" */
export enum PaymentTransactionSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockId = 'blockId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FeeAmount = 'feeAmount',
  /** column name */
  GasLimit = 'gasLimit',
  /** column name */
  GasPrice = 'gasPrice',
  /** column name */
  GasUsed = 'gasUsed',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentId = 'paymentId',
  /** column name */
  Recipient = 'recipient',
  /** column name */
  RecipientAmount = 'recipientAmount',
  /** column name */
  Sender = 'sender',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenAddress = 'tokenAddress',
  /** column name */
  TxId = 'txId',
}

/** Streaming cursor of the table "PaymentTransaction" */
export type PaymentTransactionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentTransactionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentTransactionStreamCursorValueInput = {
  amount?: InputMaybe<Scalars['numeric']>;
  blockId?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  feeAmount?: InputMaybe<Scalars['numeric']>;
  gasLimit?: InputMaybe<Scalars['numeric']>;
  gasPrice?: InputMaybe<Scalars['numeric']>;
  gasUsed?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  paymentId?: InputMaybe<Scalars['String']>;
  recipient?: InputMaybe<Scalars['String']>;
  recipientAmount?: InputMaybe<Scalars['numeric']>;
  sender?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  tokenAddress?: InputMaybe<Scalars['String']>;
  txId?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "StreamFrequency". All fields are combined with a logical 'AND'. */
export type StreamFrequencyBoolExp = {
  _and?: InputMaybe<Array<StreamFrequencyBoolExp>>;
  _not?: InputMaybe<StreamFrequencyBoolExp>;
  _or?: InputMaybe<Array<StreamFrequencyBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
};

export enum StreamFrequencyEnum {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Second = 'SECOND',
  Week = 'WEEK',
  Year = 'YEAR',
}

/** Boolean expression to compare columns of type "StreamFrequencyEnum". All fields are combined with logical 'AND'. */
export type StreamFrequencyEnumComparisonExp = {
  _eq?: InputMaybe<StreamFrequencyEnum>;
  _in?: InputMaybe<Array<StreamFrequencyEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<StreamFrequencyEnum>;
  _nin?: InputMaybe<Array<StreamFrequencyEnum>>;
};

/** Ordering options when selecting data from "StreamFrequency". */
export type StreamFrequencyOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "StreamFrequency" */
export enum StreamFrequencySelectColumn {
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "StreamFrequency" */
export type StreamFrequencyStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StreamFrequencyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StreamFrequencyStreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** order by aggregate values of table "Token" */
export type TokenAggregateOrderBy = {
  avg?: InputMaybe<TokenAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TokenMaxOrderBy>;
  min?: InputMaybe<TokenMinOrderBy>;
  stddev?: InputMaybe<TokenStddevOrderBy>;
  stddev_pop?: InputMaybe<TokenStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TokenStddevSampOrderBy>;
  sum?: InputMaybe<TokenSumOrderBy>;
  var_pop?: InputMaybe<TokenVarPopOrderBy>;
  var_samp?: InputMaybe<TokenVarSampOrderBy>;
  variance?: InputMaybe<TokenVarianceOrderBy>;
};

/** order by avg() on columns of table "Token" */
export type TokenAvgOrderBy = {
  decimals?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "Token". All fields are combined with a logical 'AND'. */
export type TokenBoolExp = {
  _and?: InputMaybe<Array<TokenBoolExp>>;
  _not?: InputMaybe<TokenBoolExp>;
  _or?: InputMaybe<Array<TokenBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrencyBoolExp>;
  currencyId?: InputMaybe<StringComparisonExp>;
  decimals?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isEnable?: InputMaybe<BooleanComparisonExp>;
  network?: InputMaybe<NetworkBoolExp>;
  networkId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** order by max() on columns of table "Token" */
export type TokenMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  networkId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "Token" */
export type TokenMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  networkId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "Token". */
export type TokenOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrencyOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isEnable?: InputMaybe<OrderBy>;
  network?: InputMaybe<NetworkOrderBy>;
  networkId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "Token" */
export enum TokenSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnable = 'isEnable',
  /** column name */
  NetworkId = 'networkId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** order by stddev() on columns of table "Token" */
export type TokenStddevOrderBy = {
  decimals?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "Token" */
export type TokenStddevPopOrderBy = {
  decimals?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "Token" */
export type TokenStddevSampOrderBy = {
  decimals?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "Token" */
export type TokenStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TokenStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TokenStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyId?: InputMaybe<Scalars['String']>;
  decimals?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['String']>;
  isEnable?: InputMaybe<Scalars['Boolean']>;
  networkId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "Token" */
export type TokenSumOrderBy = {
  decimals?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "Token" */
export type TokenVarPopOrderBy = {
  decimals?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "Token" */
export type TokenVarSampOrderBy = {
  decimals?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "Token" */
export type TokenVarianceOrderBy = {
  decimals?: InputMaybe<OrderBy>;
};

export enum UploadType {
  Avatar = 'AVATAR',
  Brand = 'BRAND',
  GovernmentId = 'GOVERNMENT_ID',
  RegisteredAddress = 'REGISTERED_ADDRESS',
  TaxRegistration = 'TAX_REGISTRATION',
  VideoVerification = 'VIDEO_VERIFICATION',
}

/** Boolean expression to filter rows from the table "User". All fields are combined with a logical 'AND'. */
export type UserBoolExp = {
  _and?: InputMaybe<Array<UserBoolExp>>;
  _not?: InputMaybe<UserBoolExp>;
  _or?: InputMaybe<Array<UserBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  avatarPath?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  companyGstRegistration?: InputMaybe<StringComparisonExp>;
  companyName?: InputMaybe<StringComparisonExp>;
  companyRole?: InputMaybe<StringComparisonExp>;
  countryId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  fullName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isAuthenticator?: InputMaybe<BooleanComparisonExp>;
  isBusiness?: InputMaybe<BooleanComparisonExp>;
  phoneNumber?: InputMaybe<StringComparisonExp>;
  publicAddress?: InputMaybe<StringComparisonExp>;
  receivedInvoices?: InputMaybe<InvoiceBoolExp>;
  sendInvoices?: InputMaybe<InvoiceBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** Ordering options when selecting data from "User". */
export type UserOrderBy = {
  address?: InputMaybe<OrderBy>;
  avatarPath?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  companyGstRegistration?: InputMaybe<OrderBy>;
  companyName?: InputMaybe<OrderBy>;
  companyRole?: InputMaybe<OrderBy>;
  countryId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAuthenticator?: InputMaybe<OrderBy>;
  isBusiness?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  publicAddress?: InputMaybe<OrderBy>;
  receivedInvoicesAggregate?: InputMaybe<InvoiceAggregateOrderBy>;
  sendInvoicesAggregate?: InputMaybe<InvoiceAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: User */
export type UserPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "User" */
export enum UserSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  AvatarPath = 'avatarPath',
  /** column name */
  City = 'city',
  /** column name */
  CompanyGstRegistration = 'companyGstRegistration',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CompanyRole = 'companyRole',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  IsAuthenticator = 'isAuthenticator',
  /** column name */
  IsBusiness = 'isBusiness',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PublicAddress = 'publicAddress',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "User" */
export type UserSetInput = {
  address?: InputMaybe<Scalars['String']>;
  avatarPath?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyGstRegistration?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyRole?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  isBusiness?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "User" */
export type UserStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserStreamCursorValueInput = {
  address?: InputMaybe<Scalars['String']>;
  avatarPath?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyGstRegistration?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyRole?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isAuthenticator?: InputMaybe<Scalars['Boolean']>;
  isBusiness?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  publicAddress?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

export type UserUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSetInput>;
  where: UserBoolExp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** Boolean expression to filter rows from the table "Whitelist". All fields are combined with a logical 'AND'. */
export type WhitelistBoolExp = {
  _and?: InputMaybe<Array<WhitelistBoolExp>>;
  _not?: InputMaybe<WhitelistBoolExp>;
  _or?: InputMaybe<Array<WhitelistBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isUse?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  networkId?: InputMaybe<StringComparisonExp>;
  ownerId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "Whitelist" */
export enum WhitelistConstraint {
  /** unique or primary key constraint on columns "id" */
  WhitelistPkey = 'Whitelist_pkey',
}

/** input type for inserting data into table "Whitelist" */
export type WhitelistInsertInput = {
  address?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  networkId?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "Whitelist" */
export type WhitelistOnConflict = {
  constraint: WhitelistConstraint;
  update_columns?: Array<WhitelistUpdateColumn>;
  where?: InputMaybe<WhitelistBoolExp>;
};

/** Ordering options when selecting data from "Whitelist". */
export type WhitelistOrderBy = {
  address?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isUse?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  networkId?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: Whitelist */
export type WhitelistPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "Whitelist" */
export enum WhitelistSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NetworkId = 'networkId',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "Whitelist" */
export type WhitelistSetInput = {
  isDelete?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "Whitelist" */
export type WhitelistStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WhitelistStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhitelistStreamCursorValueInput = {
  address?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  networkId?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "Whitelist" */
export enum WhitelistUpdateColumn {
  /** column name */
  IsDelete = 'isDelete',
  /** column name */
  Name = 'name',
}

export type WhitelistUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhitelistSetInput>;
  where: WhitelistBoolExp;
};

export type ContactFragment = {
  __typename?: 'Contact';
  id: string;
  fullName: string;
  companyName?: string | null | undefined;
  email: string;
  addressFirst?: string | null | undefined;
  addressSecond?: string | null | undefined;
  city?: string | null | undefined;
  type: ContactTypeEnum;
  postCode?: number | null | undefined;
  countryId?: string | null | undefined;
  department?: string | null | undefined;
  jobTitle?: string | null | undefined;
};

export type CountryFragment = {
  __typename?: 'Country';
  id: string;
  name: string;
  dialCode: string;
  emoji: string;
};

export type CurrencyFragment = {
  __typename?: 'Currency';
  id: string;
  name: string;
  priceUSD?: number | null | undefined;
  updatedAt: string;
  isFiat: boolean;
};

export type InvoiceFragment = {
  __typename?: 'Invoice';
  id: string;
  contractId?: string | null | undefined;
  createdAt?: string | null | undefined;
  customerId?: string | null | undefined;
  discount?: number | null | undefined;
  confirmAt?: string | null | undefined;
  dueAt?: string | null | undefined;
  isApproved?: boolean | null | undefined;
  isDeleted: boolean;
  isDrafted: boolean;
  isExpired?: boolean | null | undefined;
  isPaid: boolean;
  note?: string | null | undefined;
  ownerId: string;
  paymentId?: string | null | undefined;
  tax?: number | null | undefined;
  tokenId?: string | null | undefined;
  updatedAt?: string | null | undefined;
  vatId?: string | null | undefined;
  owner: { __typename?: 'User' } & OwnerFragment;
  accesses: Array<{
    __typename?: 'InvoiceAccess';
    id: string;
    invoiceId: string;
    isPayer: boolean;
    ownerId: string;
    userId: string;
  }>;
  contact?:
    | { __typename?: 'Contact'; id: string; fullName: string; email: string }
    | null
    | undefined;
  customer?:
    | {
        __typename?: 'User';
        id: string;
        fullName?: string | null | undefined;
        email: string;
        avatarPath?: string | null | undefined;
      }
    | null
    | undefined;
  items: Array<{ __typename?: 'InvoiceItem' } & InvoiceItemFragment>;
  extras: Array<{ __typename?: 'InvoiceExtra' } & InvoiceExtraFragment>;
  token?:
    | ({
        __typename?: 'Token';
        network: { __typename?: 'Network'; id: string; name: string };
      } & TokenFragment)
    | null
    | undefined;
};

export type InvoiceExtraFragment = {
  __typename?: 'InvoiceExtra';
  id?: string | null | undefined;
  name: string;
  value: string;
};

export type InvoiceItemFragment = {
  __typename?: 'InvoiceItem';
  id: string;
  name: string;
  quality: number;
  price: number;
  total: number;
};

export type NetworkFragment = {
  __typename?: 'Network';
  id: string;
  chainId: number;
  name: string;
  currencyId: string;
  isTestnet: boolean;
  updatedAt?: string | null | undefined;
};

export type OwnerFragment = {
  __typename?: 'User';
  id: string;
  email: string;
  fullName?: string | null | undefined;
  publicAddress?: string | null | undefined;
  avatarPath?: string | null | undefined;
  isBusiness: boolean;
};

export type PaymentAppFragment = {
  __typename?: 'PaymentApp';
  id: string;
  extras?: any | null | undefined;
  createdAt: string;
  homepage?: string | null | undefined;
  kind: PaymentKindEnum;
  logoPath?: string | null | undefined;
  name?: string | null | undefined;
  updatedAt: string;
  whitelistedCurrency?: string | null | undefined;
  whitelistedDomain?: string | null | undefined;
};

export type TokenFragment = {
  __typename?: 'Token';
  id: string;
  decimals: number;
  networkId: string;
  currencyId: string;
  updatedAt: string;
};

export type UserFragment = {
  __typename?: 'User';
  id: string;
  publicAddress?: string | null | undefined;
  fullName?: string | null | undefined;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  referrer?: string | null | undefined;
  email: string;
  createdAt?: string | null | undefined;
  companyRole?: string | null | undefined;
  companyName?: string | null | undefined;
  companyGstRegistration?: string | null | undefined;
  avatarPath?: string | null | undefined;
  isBusiness: boolean;
  phoneNumber?: string | null | undefined;
  referralCode: string | null | undefined;
  updatedAt?: string | null | undefined;
  city?: string | null | undefined;
  countryId?: string | null | undefined;
  country?: string | null | undefined;
  address?: string | null | undefined;
  isAuthenticator?: boolean | null | undefined;
};

export type WhitelistFragment = {
  __typename?: 'Whitelist';
  id: string;
  name: string;
  networkId: string;
  address: string;
  isUse?: boolean | null | undefined;
  createdAt?: string | null | undefined;
  updatedAt?: string | null | undefined;
};

export type AddContactMutationVariables = Exact<{
  object?: InputMaybe<ContactInsertInput>;
}>;

export type AddContactMutation = {
  __typename?: 'mutation_root';
  Contact?: ({ __typename?: 'Contact' } & ContactFragment) | null | undefined;
};

export type AddWhiteListMutationVariables = Exact<{
  object?: InputMaybe<WhitelistInsertInput>;
}>;

export type AddWhiteListMutation = {
  __typename?: 'mutation_root';
  whitelist?: ({ __typename?: 'Whitelist' } & WhitelistFragment) | null | undefined;
};

export type CreateDraftInvoiceMutationVariables = Exact<{ [key: string]: never }>;

export type CreateDraftInvoiceMutation = {
  __typename?: 'mutation_root';
  createDraftInvoice?: { __typename?: 'DraftInvoiceOutput'; invoiceId: string } | null | undefined;
};

export type GenerateAuthenticatorMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateAuthenticatorMutation = {
  __typename?: 'mutation_root';
  generateAuthenticator?:
    | { __typename?: 'AuthenticatorOutput'; otpauth: string; secret: string }
    | null
    | undefined;
};

export type LoginMutationVariables = Exact<{
  didToken: Scalars['String'];
}>;

export type LoginMutation = {
  __typename?: 'mutation_root';
  login?: { __typename?: 'LoginOutput'; token: string } | null | undefined;
};

export type SendInvoiceMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type SendInvoiceMutation = {
  __typename?: 'mutation_root';
  sendInvoice?: { __typename?: 'SendInvoiceOutput'; invoiceId: string } | null | undefined;
};

export type TurnOffAuthenticatorMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type TurnOffAuthenticatorMutation = {
  __typename?: 'mutation_root';
  turnOffAuthenticator: boolean;
};

export type TurnOnAuthenticatorMutationVariables = Exact<{
  secret: Scalars['String'];
  token: Scalars['String'];
}>;

export type TurnOnAuthenticatorMutation = {
  __typename?: 'mutation_root';
  turnOnAuthenticator: boolean;
};

export type UpdateContractMutationVariables = Exact<{
  _set?: InputMaybe<ContactSetInput>;
  id: Scalars['uuid'];
}>;

export type UpdateContractMutation = {
  __typename?: 'mutation_root';
  updateContactByPk?: ({ __typename?: 'Contact' } & ContactFragment) | null | undefined;
};

export type UpdateInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['String'];
  invoiceSetInput?: InputMaybe<InvoiceSetInput>;
  itemInputs?: InputMaybe<Array<InvoiceItemInsertInput> | InvoiceItemInsertInput>;
  itemDeleteIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  extraInputs?: InputMaybe<Array<InvoiceExtraInsertInput> | InvoiceExtraInsertInput>;
  extraDeleteIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type UpdateInvoiceMutation = {
  __typename?: 'mutation_root';
  deleteInvoiceItem?:
    | { __typename?: 'InvoiceItemMutationResponse'; affected_rows: number }
    | null
    | undefined;
  insertInvoiceItem?:
    | { __typename?: 'InvoiceItemMutationResponse'; affected_rows: number }
    | null
    | undefined;
  deleteInvoiceExtra?:
    | { __typename?: 'InvoiceExtraMutationResponse'; affected_rows: number }
    | null
    | undefined;
  insertInvoiceExtra?:
    | { __typename?: 'InvoiceExtraMutationResponse'; affected_rows: number }
    | null
    | undefined;
  invoice?: ({ __typename?: 'Invoice' } & InvoiceFragment) | null | undefined;
};

export type UpdateInvoiceItemMutationVariables = Exact<{
  id?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  _set?: InputMaybe<InvoiceItemSetInput>;
}>;

export type UpdateInvoiceItemMutation = {
  __typename?: 'mutation_root';
  updateInvoiceItem?:
    | { __typename?: 'InvoiceItemMutationResponse'; affected_rows: number }
    | null
    | undefined;
};

export type UpdateInvoiceItemByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: InputMaybe<InvoiceItemSetInput>;
}>;

export type UpdateInvoiceItemByPkMutation = {
  __typename?: 'mutation_root';
  updateInvoiceItemByPk?: { __typename?: 'InvoiceItem'; id: string } | null | undefined;
};

export type UpdateProfileMutationVariables = Exact<{
  userId: Scalars['String'];
  _set?: InputMaybe<UserSetInput>;
}>;

export type UpdateProfileMutation = {
  __typename?: 'mutation_root';
  user?: ({ __typename?: 'User' } & UserFragment) | null | undefined;
};

export type UpdateWhiteListMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: InputMaybe<WhitelistSetInput>;
}>;

export type UpdateWhiteListMutation = {
  __typename?: 'mutation_root';
  updateWhitelistByPk?: ({ __typename?: 'Whitelist' } & WhitelistFragment) | null | undefined;
};

export type VerifyAuthenticatorMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type VerifyAuthenticatorMutation = {
  __typename?: 'mutation_root';
  verifyAuthenticator: boolean;
};

export type GetAppByUserAndKindQueryVariables = Exact<{
  ownerId: Scalars['String'];
  kind?: InputMaybe<PaymentKindEnum>;
}>;

export type GetAppByUserAndKindQuery = {
  __typename?: 'query_root';
  paymentApp: Array<{ __typename?: 'PaymentApp' } & PaymentAppFragment>;
};

export type GetContactForInvoiceQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContactBoolExp>;
}>;

export type GetContactForInvoiceQuery = {
  __typename?: 'query_root';
  contact: Array<{ __typename?: 'Contact' } & ContactFragment>;
};

export type GetContactsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContactBoolExp>;
}>;

export type GetContactsQuery = {
  __typename?: 'query_root';
  paging: {
    __typename?: 'ContactAggregate';
    items?: { __typename?: 'ContactAggregateFields'; count: number } | null | undefined;
  };
  contacts: Array<{ __typename?: 'Contact' } & ContactFragment>;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
  __typename?: 'query_root';
  countries: Array<{ __typename?: 'Country' } & CountryFragment>;
};

export type GetCurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrenciesQuery = {
  __typename?: 'query_root';
  currencies: Array<{ __typename?: 'Currency' } & CurrencyFragment>;
};

export type GetInvoiceByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetInvoiceByIdQuery = {
  __typename?: 'query_root';
  invoice?: ({ __typename?: 'Invoice' } & InvoiceFragment) | null | undefined;
};

export type GetInvoicesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvoiceBoolExp>;
}>;

export type GetInvoicesQuery = {
  __typename?: 'query_root';
  pagination: {
    __typename?: 'InvoiceAggregate';
    aggregate?: { __typename?: 'InvoiceAggregateFields'; count: number } | null | undefined;
  };
  invoices: Array<{ __typename?: 'Invoice' } & InvoiceFragment>;
};

export type GetNetworkAndTokensQueryVariables = Exact<{ [key: string]: never }>;

export type GetNetworkAndTokensQuery = {
  __typename?: 'query_root';
  network: Array<
    {
      __typename?: 'Network';
      tokens: Array<
        {
          __typename?: 'Token';
          currency: { __typename?: 'Currency'; id: string; name: string };
        } & TokenFragment
      >;
    } & NetworkFragment
  >;
};

export type GetNetworksQueryVariables = Exact<{ [key: string]: never }>;

export type GetNetworksQuery = {
  __typename?: 'query_root';
  networks: Array<{ __typename?: 'Network' } & NetworkFragment>;
};

export type GetTokensQueryVariables = Exact<{ [key: string]: never }>;

export type GetTokensQuery = {
  __typename?: 'query_root';
  tokens: Array<{ __typename?: 'Token' } & TokenFragment>;
};

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetUserByIdQuery = {
  __typename?: 'query_root';
  user?: ({ __typename?: 'User' } & UserFragment) | null | undefined;
};

export type GetWhitelistQueryVariables = Exact<{ [key: string]: never }>;

export type GetWhitelistQuery = {
  __typename?: 'query_root';
  whitelists: Array<{ __typename?: 'Whitelist' } & WhitelistFragment>;
};

export const ContactFragmentDoc = gql`
  fragment Contact on Contact {
    id
    fullName
    companyName
    email
    addressFirst
    addressSecond
    city
    type
    postCode
    countryId
    department
    jobTitle
    type
  }
`;
export const CountryFragmentDoc = gql`
  fragment Country on Country {
    id
    name
    dialCode
    emoji
  }
`;
export const CurrencyFragmentDoc = gql`
  fragment Currency on Currency {
    id
    name
    priceUSD
    updatedAt
    isFiat
  }
`;
export const OwnerFragmentDoc = gql`
  fragment Owner on User {
    id
    email
    fullName
    publicAddress
    avatarPath
    isBusiness
  }
`;
export const InvoiceItemFragmentDoc = gql`
  fragment InvoiceItem on InvoiceItem {
    id
    name
    quality
    price
    total
  }
`;
export const InvoiceExtraFragmentDoc = gql`
  fragment InvoiceExtra on InvoiceExtra {
    id
    name
    value
  }
`;
export const TokenFragmentDoc = gql`
  fragment Token on Token {
    id
    decimals
    networkId
    currencyId
    updatedAt
  }
`;
export const InvoiceFragmentDoc = gql`
  fragment Invoice on Invoice {
    id
    contractId
    createdAt
    customerId
    discount
    confirmAt
    dueAt
    isApproved
    isDeleted
    isDrafted
    isExpired
    isPaid
    note
    ownerId
    owner {
      ...Owner
    }
    paymentId
    tax
    tokenId
    updatedAt
    vatId
    accesses {
      id
      invoiceId
      isPayer
      ownerId
      userId
    }
    contact {
      id
      fullName
      email
    }
    customer {
      id
      fullName
      email
      avatarPath
    }
    items {
      ...InvoiceItem
    }
    extras {
      ...InvoiceExtra
    }
    token {
      ...Token
      network {
        id
        name
      }
    }
  }
  ${OwnerFragmentDoc}
  ${InvoiceItemFragmentDoc}
  ${InvoiceExtraFragmentDoc}
  ${TokenFragmentDoc}
`;
export const NetworkFragmentDoc = gql`
  fragment Network on Network {
    id
    chainId
    name
    currencyId
    isTestnet
    updatedAt
  }
`;
export const PaymentAppFragmentDoc = gql`
  fragment PaymentApp on PaymentApp {
    id
    extras
    createdAt
    homepage
    kind
    logoPath
    name
    updatedAt
    whitelistedCurrency
    whitelistedDomain
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    publicAddress
    fullName
    email
    createdAt
    companyRole
    companyName
    companyGstRegistration
    avatarPath
    isBusiness
    phoneNumber
    updatedAt
    city
    countryId
    address
    isAuthenticator
  }
`;
export const WhitelistFragmentDoc = gql`
  fragment Whitelist on Whitelist {
    id
    name
    networkId
    address
    isUse
    createdAt
    updatedAt
  }
`;
export const AddContactDocument = gql`
  mutation addContact($object: ContactInsertInput = {}) {
    Contact: insertContactOne(object: $object) {
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;

export function useAddContactMutation() {
  return Urql.useMutation<AddContactMutation, AddContactMutationVariables>(AddContactDocument);
}
export const AddWhiteListDocument = gql`
  mutation addWhiteList($object: WhitelistInsertInput = {}) {
    whitelist: insertWhitelistOne(object: $object) {
      ...Whitelist
    }
  }
  ${WhitelistFragmentDoc}
`;

export function useAddWhiteListMutation() {
  return Urql.useMutation<AddWhiteListMutation, AddWhiteListMutationVariables>(
    AddWhiteListDocument
  );
}
export const CreateDraftInvoiceDocument = gql`
  mutation createDraftInvoice {
    createDraftInvoice {
      invoiceId
    }
  }
`;

export function useCreateDraftInvoiceMutation() {
  return Urql.useMutation<CreateDraftInvoiceMutation, CreateDraftInvoiceMutationVariables>(
    CreateDraftInvoiceDocument
  );
}
export const GenerateAuthenticatorDocument = gql`
  mutation generateAuthenticator {
    generateAuthenticator {
      otpauth
      secret
    }
  }
`;

export function useGenerateAuthenticatorMutation() {
  return Urql.useMutation<GenerateAuthenticatorMutation, GenerateAuthenticatorMutationVariables>(
    GenerateAuthenticatorDocument
  );
}
export const LoginDocument = gql`
  mutation login($didToken: String!) {
    login(didToken: $didToken) {
      token
    }
  }
`;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
}
export const SendInvoiceDocument = gql`
  mutation sendInvoice($id: String!) {
    sendInvoice(invoiceId: $id) {
      invoiceId
    }
  }
`;

export function useSendInvoiceMutation() {
  return Urql.useMutation<SendInvoiceMutation, SendInvoiceMutationVariables>(SendInvoiceDocument);
}
export const TurnOffAuthenticatorDocument = gql`
  mutation turnOffAuthenticator($token: String!) {
    turnOffAuthenticator(token: $token)
  }
`;

export function useTurnOffAuthenticatorMutation() {
  return Urql.useMutation<TurnOffAuthenticatorMutation, TurnOffAuthenticatorMutationVariables>(
    TurnOffAuthenticatorDocument
  );
}
export const TurnOnAuthenticatorDocument = gql`
  mutation turnOnAuthenticator($secret: String!, $token: String!) {
    turnOnAuthenticator(secret: $secret, token: $token)
  }
`;

export function useTurnOnAuthenticatorMutation() {
  return Urql.useMutation<TurnOnAuthenticatorMutation, TurnOnAuthenticatorMutationVariables>(
    TurnOnAuthenticatorDocument
  );
}
export const UpdateContractDocument = gql`
  mutation updateContract($_set: ContactSetInput = {}, $id: uuid!) {
    updateContactByPk(_set: $_set, pk_columns: { id: $id }) {
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;

export function useUpdateContractMutation() {
  return Urql.useMutation<UpdateContractMutation, UpdateContractMutationVariables>(
    UpdateContractDocument
  );
}
export const UpdateInvoiceDocument = gql`
  mutation updateInvoice(
    $invoiceId: String!
    $invoiceSetInput: InvoiceSetInput = {}
    $itemInputs: [InvoiceItemInsertInput!] = {}
    $itemDeleteIds: [uuid!] = []
    $extraInputs: [InvoiceExtraInsertInput!] = {}
    $extraDeleteIds: [uuid!] = []
  ) {
    deleteInvoiceItem(where: { id: { _in: $itemDeleteIds } }) {
      affected_rows
    }
    insertInvoiceItem(
      objects: $itemInputs
      onConflict: { constraint: InvoiceItem_pkey, update_columns: [name, price, quality] }
    ) {
      affected_rows
    }
    deleteInvoiceExtra(where: { id: { _in: $extraDeleteIds } }) {
      affected_rows
    }
    insertInvoiceExtra(
      objects: $extraInputs
      onConflict: { constraint: InvoiceExtra_pkey, update_columns: [name, value] }
    ) {
      affected_rows
    }
    invoice: updateInvoiceByPk(pk_columns: { id: $invoiceId }, _set: $invoiceSetInput) {
      ...Invoice
    }
  }
  ${InvoiceFragmentDoc}
`;

export function useUpdateInvoiceMutation() {
  return Urql.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(
    UpdateInvoiceDocument
  );
}
export const UpdateInvoiceItemDocument = gql`
  mutation updateInvoiceItem($id: [uuid!], $_set: InvoiceItemSetInput) {
    updateInvoiceItem(where: { id: { _in: $id } }, _set: $_set) {
      affected_rows
    }
  }
`;

export function useUpdateInvoiceItemMutation() {
  return Urql.useMutation<UpdateInvoiceItemMutation, UpdateInvoiceItemMutationVariables>(
    UpdateInvoiceItemDocument
  );
}
export const UpdateInvoiceItemByPkDocument = gql`
  mutation updateInvoiceItemByPk($id: uuid!, $_set: InvoiceItemSetInput) {
    updateInvoiceItemByPk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

export function useUpdateInvoiceItemByPkMutation() {
  return Urql.useMutation<UpdateInvoiceItemByPkMutation, UpdateInvoiceItemByPkMutationVariables>(
    UpdateInvoiceItemByPkDocument
  );
}
export const UpdateProfileDocument = gql`
  mutation updateProfile($userId: String!, $_set: UserSetInput = {}) {
    user: updateUserByPk(pk_columns: { id: $userId }, _set: $_set) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

export function useUpdateProfileMutation() {
  return Urql.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(
    UpdateProfileDocument
  );
}
export const UpdateWhiteListDocument = gql`
  mutation updateWhiteList($id: uuid!, $_set: WhitelistSetInput = {}) {
    updateWhitelistByPk(pk_columns: { id: $id }, _set: $_set) {
      ...Whitelist
    }
  }
  ${WhitelistFragmentDoc}
`;

export function useUpdateWhiteListMutation() {
  return Urql.useMutation<UpdateWhiteListMutation, UpdateWhiteListMutationVariables>(
    UpdateWhiteListDocument
  );
}
export const VerifyAuthenticatorDocument = gql`
  mutation verifyAuthenticator($token: String!) {
    verifyAuthenticator(token: $token)
  }
`;

export function useVerifyAuthenticatorMutation() {
  return Urql.useMutation<VerifyAuthenticatorMutation, VerifyAuthenticatorMutationVariables>(
    VerifyAuthenticatorDocument
  );
}
export const GetAppByUserAndKindDocument = gql`
  query getAppByUserAndKind($ownerId: String!, $kind: PaymentKindEnum = INVOICE) {
    paymentApp(where: { ownerId: { _eq: $ownerId }, kind: { _eq: $kind } }) {
      ...PaymentApp
    }
  }
  ${PaymentAppFragmentDoc}
`;

export function useGetAppByUserAndKindQuery(
  options: Omit<Urql.UseQueryArgs<GetAppByUserAndKindQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetAppByUserAndKindQuery, GetAppByUserAndKindQueryVariables>({
    query: GetAppByUserAndKindDocument,
    ...options,
  });
}
export const GetContactForInvoiceDocument = gql`
  query getContactForInvoice($limit: Int = 10, $where: ContactBoolExp = {}) {
    contact(limit: $limit, where: $where, orderBy: { createdAt: DESC }) {
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;

export function useGetContactForInvoiceQuery(
  options?: Omit<Urql.UseQueryArgs<GetContactForInvoiceQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetContactForInvoiceQuery, GetContactForInvoiceQueryVariables>({
    query: GetContactForInvoiceDocument,
    ...options,
  });
}
export const GetContactsDocument = gql`
  query getContacts($limit: Int = 10, $offset: Int = 0, $where: ContactBoolExp = {}) {
    paging: contactAggregate(where: $where) {
      items: aggregate {
        count
      }
    }
    contacts: contact(limit: $limit, offset: $offset, where: $where, orderBy: { updatedAt: DESC }) {
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;

export function useGetContactsQuery(
  options?: Omit<Urql.UseQueryArgs<GetContactsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetContactsQuery, GetContactsQueryVariables>({
    query: GetContactsDocument,
    ...options,
  });
}
export const GetCountriesDocument = gql`
  query getCountries {
    countries: country {
      ...Country
    }
  }
  ${CountryFragmentDoc}
`;

export function useGetCountriesQuery(
  options?: Omit<Urql.UseQueryArgs<GetCountriesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCountriesQuery, GetCountriesQueryVariables>({
    query: GetCountriesDocument,
    ...options,
  });
}
export const GetCurrenciesDocument = gql`
  query getCurrencies {
    currencies: currency {
      ...Currency
    }
  }
  ${CurrencyFragmentDoc}
`;

export function useGetCurrenciesQuery(
  options?: Omit<Urql.UseQueryArgs<GetCurrenciesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>({
    query: GetCurrenciesDocument,
    ...options,
  });
}
export const GetInvoiceByIdDocument = gql`
  query getInvoiceById($id: String!) {
    invoice: invoiceByPk(id: $id) {
      ...Invoice
    }
  }
  ${InvoiceFragmentDoc}
`;

export function useGetInvoiceByIdQuery(
  options: Omit<Urql.UseQueryArgs<GetInvoiceByIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>({
    query: GetInvoiceByIdDocument,
    ...options,
  });
}
export const GetInvoicesDocument = gql`
  query getInvoices($limit: Int = 10, $offset: Int = 0, $where: InvoiceBoolExp = {}) {
    pagination: invoiceAggregate(where: $where) {
      aggregate {
        count
      }
    }
    invoices: invoice(limit: $limit, offset: $offset, orderBy: { updatedAt: DESC }, where: $where) {
      ...Invoice
    }
  }
  ${InvoiceFragmentDoc}
`;

export function useGetInvoicesQuery(
  options?: Omit<Urql.UseQueryArgs<GetInvoicesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>({
    query: GetInvoicesDocument,
    ...options,
  });
}
export const GetNetworkAndTokensDocument = gql`
  query getNetworkAndTokens {
    network {
      ...Network
      tokens {
        ...Token
        currency {
          id
          name
        }
      }
    }
  }
  ${NetworkFragmentDoc}
  ${TokenFragmentDoc}
`;

export function useGetNetworkAndTokensQuery(
  options?: Omit<Urql.UseQueryArgs<GetNetworkAndTokensQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetNetworkAndTokensQuery, GetNetworkAndTokensQueryVariables>({
    query: GetNetworkAndTokensDocument,
    ...options,
  });
}
export const GetNetworksDocument = gql`
  query getNetworks {
    networks: network {
      ...Network
    }
  }
  ${NetworkFragmentDoc}
`;

export function useGetNetworksQuery(
  options?: Omit<Urql.UseQueryArgs<GetNetworksQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetNetworksQuery, GetNetworksQueryVariables>({
    query: GetNetworksDocument,
    ...options,
  });
}
export const GetTokensDocument = gql`
  query getTokens {
    tokens: token {
      ...Token
    }
  }
  ${TokenFragmentDoc}
`;

export function useGetTokensQuery(
  options?: Omit<Urql.UseQueryArgs<GetTokensQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetTokensQuery, GetTokensQueryVariables>({
    query: GetTokensDocument,
    ...options,
  });
}
export const GetUserByIdDocument = gql`
  query getUserById($id: String!) {
    user: userByPk(id: $id) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

export function useGetUserByIdQuery(
  options: Omit<Urql.UseQueryArgs<GetUserByIdQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>({
    query: GetUserByIdDocument,
    ...options,
  });
}
export const GetWhitelistDocument = gql`
  query getWhitelist {
    whitelists: whitelist(orderBy: { updatedAt: DESC }) {
      ...Whitelist
    }
  }
  ${WhitelistFragmentDoc}
`;

export function useGetWhitelistQuery(
  options?: Omit<Urql.UseQueryArgs<GetWhitelistQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetWhitelistQuery, GetWhitelistQueryVariables>({
    query: GetWhitelistDocument,
    ...options,
  });
}
