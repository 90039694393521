import { NETWORK, getMagic, getMagicProvider, getConfig } from 'chains';
import { useMemo } from 'react';

const useMagic = (network: string) => {
  const magic = useMemo(() => getMagic(network as NETWORK), [network]);
  const provider = useMemo(() => getMagicProvider(network as NETWORK), [network]);
  const config = getConfig(network as NETWORK);
  return { magic, provider, currency: config?.currency };
};

export default useMagic;
