import { HStack, IconButton, Icon, useColorMode } from '@chakra-ui/react';
import { HiMoon, HiOutlineSun } from 'react-icons/hi';
import { ColorMix } from 'theme/types';
import Currency from './Currency';
import UserProfile from './UserProfile';

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <HStack h={['60px', '70px', '80px']} justify={'flex-end'} spacing="2">
      {/* <Currency /> */}
      <IconButton
        color={ColorMix.textHint}
        size={'lg'}
        variant={'ghost'}
        icon={<Icon as={colorMode === 'dark' ? HiOutlineSun : HiMoon} w="32px" h="32px" />}
        aria-label="Dark mode"
        onClick={toggleColorMode}
      />
      {/* <IconButton
        color={ColorMix.textHint}
        size={'lg'}
        variant={'ghost'}
        icon={<Icon as={HiOutlineBell} w="32px" h="32px" />}
        aria-label="Notifications"
      /> */}
      <UserProfile />
    </HStack>
  );
};
export default Header;
