import { Magic } from 'magic-sdk';
import { ethers } from 'ethers';
import { getConfig } from './network';
import { NETWORK, ProviderConfig } from './types';

const magicKey = process.env.REACT_APP_MAGIC_API_KEY || '';
const magics: { [key: string]: Magic } = {};
const magicProviders: { [key: string]: ethers.providers.Web3Provider } = {};

export const getMagic = (network: NETWORK) => {
  if (!magics[network]) {
    const config = getConfig(network);
    magics[network] = createMagic(config!);
  }
  return magics[network];
};

export const getMagicProvider = (network: NETWORK) => {
  if (!magicProviders[network]) {
    const magic = getMagic(network);
    // @ts-ignore
    magicProviders[network] = new ethers.providers.Web3Provider(magic.rpcProvider);
  }
  return magicProviders[network];
};

const createMagic = (config: ProviderConfig) => {
  return new Magic(magicKey, {
    network: {
      rpcUrl: config.rpcUrl,
      chainId: config.chainId,
    },
  });
};
