import { Flex } from '@chakra-ui/react';
import { useAuth } from 'hooks';
import { Navigate, Outlet } from 'react-router-dom';
import Header from './Header';
import MobileTopBar from './MobileTopBar';
import Sidebar from './Sidebar';

const SidebarLayout = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to={'/waitlist'} />;
  }

  return (
    <Flex flexDirection="column">
      <MobileTopBar />
      <Flex flex="1" overflow="hidden" minH="100vh">
        <Sidebar display={{ base: 'none', lg: 'flex' }} />
        <Flex flex="1" bg={'bgContent'} flexDirection="column" px={['20px', '30px', '40px']}>
          <Header />
          <Outlet />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SidebarLayout;
