import { chakra, HTMLChakraProps, useColorModeValue, useToken } from '@chakra-ui/react';

interface LogoProps extends HTMLChakraProps<'svg'> {}
const Logo = ({ ...rest }: LogoProps) => {
  const color = useColorModeValue('black', 'white');
  const [main] = useToken('colors', ['primary.500']);
  const iconColor = main;
  return (
    <chakra.svg width="1000" height="260" viewBox="0 0 1000 260" fill="none" {...rest}>
      <path
        d="M343.662 189.028V142.861C350.133 149.963 359.84 154.644 370.518 154.644C394.624 154.644 411.935 136.888 411.935 112.997C411.935 90.3976 393.815 71.6724 369.871 71.6724C345.927 71.6724 327.645 89.4291 327.645 112.997V189.028H343.662ZM369.871 139.632C354.987 139.632 343.662 127.525 343.662 113.158C343.662 98.6303 354.987 86.8463 369.871 86.8463C384.593 86.8463 395.918 98.6303 395.918 113.158C395.918 127.525 384.593 139.632 369.871 139.632Z"
        fill={color}
      />
      <path
        d="M456.719 154.644C469.662 154.644 480.178 148.51 486.164 138.825V152.707H501.372V112.997C501.372 89.4291 483.09 71.6724 459.146 71.6724C435.04 71.6724 416.92 90.3976 416.92 112.997C416.92 135.919 433.746 154.644 456.719 154.644ZM459.146 139.471C444.424 139.471 432.937 127.525 432.937 113.158C432.937 98.6303 444.424 86.6849 459.146 86.6849C474.03 86.6849 485.355 98.6303 485.355 113.158C485.355 127.525 474.03 139.471 459.146 139.471Z"
        fill={color}
      />
      <path
        d="M552.466 191.126C578.19 191.126 593.721 172.885 593.721 146.412V73.6095H577.543V112.674C577.543 127.202 568.483 138.825 554.569 138.825C540.17 138.825 532.405 128.655 532.405 113.804V73.6095H516.55V114.611C516.55 137.856 528.36 153.837 552.628 153.837C564.6 153.837 573.013 148.51 577.705 139.632V146.573C577.705 163.684 568.968 176.275 552.143 176.275C541.303 176.275 532.405 171.271 527.875 162.716L514.608 169.98C521.727 184.024 535.64 191.126 552.466 191.126Z"
        fill={color}
      />
      <path
        d="M635.435 154.806C643.687 154.806 649.187 149.963 651.452 140.439L664.557 88.1377C664.88 86.5235 665.689 85.5549 667.145 85.5549C668.763 85.5549 669.572 86.5235 670.058 88.1377L683 140.439C685.265 149.963 690.766 154.806 699.017 154.806C706.945 154.806 713.578 149.963 715.843 140.278L730.889 73.6095H716.005L701.929 137.211C701.444 139.148 700.635 140.116 699.179 140.116C697.561 140.116 696.752 139.148 696.267 137.534L683.486 85.8778C681.059 76.1923 675.72 71.6724 667.307 71.6724C658.894 71.6724 653.394 76.3537 650.967 86.0392L638.024 137.534C637.7 138.986 636.73 140.116 635.435 140.116C633.656 140.116 633.009 139.148 632.523 137.211L617.963 73.6095H602.917L618.448 140.278C620.713 149.963 627.508 154.806 635.435 154.806Z"
        fill={color}
      />
      <path
        d="M774.568 154.644C798.351 154.644 816.633 135.919 816.633 112.997C816.633 90.3976 798.513 71.6724 774.568 71.6724C750.786 71.6724 732.181 90.2362 732.181 112.997C732.181 135.919 750.624 154.644 774.568 154.644ZM774.568 139.471C759.684 139.471 748.197 127.525 748.197 113.158C748.197 98.6303 759.684 86.6849 774.568 86.6849C789.291 86.6849 800.616 98.6303 800.616 113.158C800.616 127.525 789.291 139.471 774.568 139.471Z"
        fill={color}
      />
      <path
        d="M865.745 87.0077C881.6 87.0077 889.689 99.7602 889.689 114.611V152.707H905.706V112.674C905.706 89.7519 891.63 71.6724 865.745 71.6724C840.183 71.6724 826.431 89.7519 826.431 112.836V152.707H842.448V114.45C842.448 99.7602 850.213 87.0077 865.745 87.0077Z"
        fill={color}
      />
      <path
        d="M956.318 190.965C981.556 190.965 1000 173.047 1000 145.605V112.997C1000 89.4291 981.718 71.6724 957.774 71.6724C933.668 71.6724 915.548 90.2362 915.548 112.836C915.548 136.081 933.344 153.837 955.833 153.837C969.099 153.837 978.806 147.703 983.983 139.471V145.766C983.983 164.33 972.658 176.275 955.994 176.275C944.508 176.275 935.771 170.787 931.079 162.231L917.651 169.495C924.932 183.539 939.169 190.965 956.318 190.965ZM957.612 138.825C943.213 138.825 931.403 127.202 931.403 112.674C931.403 98.146 943.213 86.8463 957.612 86.8463C972.173 86.8463 983.821 98.146 983.821 112.674C983.821 127.202 972.173 138.825 957.612 138.825Z"
        fill={color}
      />
      <path
        d="M27.4644 207.963V150.763C27.4644 125.483 47.9581 104.989 73.2383 104.989V80.286C32.7899 80.286 0 113.076 0 153.524V205.983C0 235.476 23.9093 259.386 53.4029 259.386C82.8966 259.386 106.806 235.476 106.806 205.983V29.3353H165.487C179.169 29.3353 193.161 31.1427 204.21 39.2135C217.011 48.5649 231.921 65.3205 231.921 92.6377C231.921 116.255 220.776 131.978 209.485 141.847C195.817 153.793 176.889 155.94 158.736 155.94H150.446C143.198 155.94 137.322 161.816 137.322 169.064C137.322 176.312 143.198 182.188 150.446 182.188H169.546C181.603 182.188 193.784 181.288 204.802 176.39C226.015 166.959 259.386 143.755 259.386 91.0938C259.386 33.5143 219.491 11.151 199.214 3.50322C191.693 0.666603 183.626 0 175.588 0H99.8823C87.6953 0 77.8157 9.87957 77.8157 22.0666V207.963C77.8157 221.867 66.5442 233.138 52.64 233.138C38.7359 233.138 27.4644 221.867 27.4644 207.963Z"
        fill={iconColor}
      />
      <path
        d="M111.383 104.989V80.286H147.857C154.679 80.286 160.209 85.8161 160.209 92.6377C160.209 99.4594 154.679 104.989 147.857 104.989H111.383Z"
        fill={iconColor}
      />
    </chakra.svg>
  );
};

export default Logo;
