import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/ms';
import common from './en/common.json';
import nav from './en/nav.json';
import login from './en/login.json';
import balances from './en/balances.json';
import account from './en/account.json';
import invoices from './en/invoices.json';
import withdraw from './en/withdraw.json';
import authenticator from './en/authenticator.json';
import contact from './en/contact.json';
import settings from './en/settings.json';

export const defaultNS = 'common';

export const resources = {
  en: {
    common,
    login,
    balances,
    nav,
    account,
    invoices,
    withdraw,
    authenticator,
    contact,
    settings,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'en',
  ns: [
    'common',
    'login',
    'balances',
    'nav',
    'account',
    'invoices',
    'withdraw',
    'authenticator',
    'contact',
    'settings',
  ],
  defaultNS,
  resources,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
});

i18n.on('languageChanged', (lng) => {
  dayjs.locale(lng);
});
