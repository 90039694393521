import Dexie, { Table } from 'dexie';

export interface Account {
  id?: string;
  userId: string;
  publicAddress: string;
  networkId: string;
  none: number;
  balance: string;
  updatedAt: number;
}

export interface Network {
  id?: string;
  name: string;
  currencyId: string;
  chainId: number;
  isTestnet: boolean;
  updatedAt: number;
}

export interface Token {
  id?: string;
  decimals: number;
  currencyId: string;
  name: string;
  networkId: string;
  updatedAt: number;
}

export interface TokenBalance {
  id?: string;
  userId: string;
  tokenId: string;
  balance: string;
}

const DB_VERSION = 3;

export class WalletDbDexie extends Dexie {
  accounts!: Table<Account>;
  networks!: Table<Network>;
  tokens!: Table<Token>;
  tokenBalances!: Table<TokenBalance>;
  constructor() {
    super('pw-wallet');
    this.version(DB_VERSION).stores({
      accounts: '&id, userId, [publicAddress+networkId]',
      networks: '&id, updatedAt',
      tokens: '&id, networkId, currencyId, updatedAt',
      tokenBalances: '++id, userId, tokenId',
    });
  }
}

export const db = new WalletDbDexie();
