import { NETWORK, ProviderConfig } from './types';

export const providerConfigs: Record<NETWORK, ProviderConfig> = {
  ethereum: {
    name: 'ethereum',
    rpcUrl: 'https://rpc.ankr.com/eth',
    chainId: 1,
    scanUrl: 'https://etherscan.io',
    currency: 'ETH',
  },
  polygon: {
    name: 'polygon',
    rpcUrl: 'https://rpc.ankr.com/polygon',
    chainId: 137,
    scanUrl: 'https://polygonscan.com',
    currency: 'MATIC',
  },
  arbitrum: {
    name: 'arbitrum',
    rpcUrl: 'https://rpc.ankr.com/arbitrum',
    chainId: 42161,
    scanUrl: 'https://arbiscan.io',
    currency: 'ETH',
  },
  bsc: {
    name: 'binance',
    rpcUrl: 'https://rpc.ankr.com/bsc',
    chainId: 56,
    scanUrl: 'https://www.bscscan.com',
    currency: 'BNB',
  },
  moonbeam: {
    name: 'moonbeam',
    rpcUrl: 'https://rpc.ankr.com/moonbeam',
    chainId: 1284,
    scanUrl: 'https://moonscan.io',
    currency: 'GLMR',
  },
  fantom: {
    name: 'fantom',
    rpcUrl: 'https://rpc.ankr.com/fantom',
    chainId: 250,
    scanUrl: 'https://ftmscan.com',
    currency: 'FTM',
  },
  'avax-c-chain': {
    name: 'avax-c-chain',
    rpcUrl: 'https://rpc.ankr.com/avalanche',
    chainId: 43114,
    currency: 'avax',
  },
  "bsc-testnet": {
    name: 'binance-testnet',
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    chainId: 97,
    currency: 'BNB',
  }
};
