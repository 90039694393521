import { useGetNetworkAndTokensQuery } from 'client';
import dayjs from 'dayjs';
import { useAuth } from 'hooks';
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { syncAccount, syncToDb, syncFromChains } from './sync';

interface WalletContextType {
  isSyncAll: boolean;
  isLoading: boolean;
  syncAll: () => void;
  currency: string;
  changeCurrency: (currency: string) => void;
}

export const WalletContext = createContext<WalletContextType>({
  isSyncAll: false,
  syncAll: () => Promise.resolve(),
  isLoading: false,
  currency: 'usd',
  changeCurrency: () => {},
});

type WalletProviderProps = {
  children: ReactNode;
};

const TIME_DELAY_UPDATE_BALANCE = 1000 * 30;

export const WalletProvider = ({ children }: WalletProviderProps) => {
  const [isSyncAll, setSyncAll] = useState(false);
  const [isInitData, setInitData] = useState(false);
  const [isCreateAccount, setCreateAccount] = useState(false);
  const { isAuthenticated, userId } = useAuth();
  const [currency, setCurrency] = useState(localStorage.getItem('currency') || 'usd');

  const [{ data }] = useGetNetworkAndTokensQuery();

  const changeCurrency = useCallback((currency: string) => {
    localStorage.setItem('currency', currency);
    setCurrency(currency);
  }, []);

  const doSyncAll = useCallback(() => {
    if (!isSyncAll) {
      setSyncAll(true);
      syncFromChains(userId!).finally(() => {
        localStorage.setItem('lastSyncAt', dayjs().format());
        setSyncAll(false);
      });
    }
  }, [isSyncAll, userId]);

  useEffect(() => {
    let sub = true;

    const doJobs = () => {
      if (sub) {
        const lastSyncAt = dayjs(localStorage.getItem('lastSyncAt'));
        const now = dayjs();
        const canSync = (now.unix() - lastSyncAt.unix()) * 1000 >= TIME_DELAY_UPDATE_BALANCE;
        if (canSync && isAuthenticated && !isSyncAll) {
          console.log('do doSyncAll with timer');
          doSyncAll();
        }
        setTimeout(doJobs, TIME_DELAY_UPDATE_BALANCE);
      }
    };
    doJobs();
    return () => {
      sub = false;
    };
  }, [doSyncAll, isAuthenticated, isSyncAll, userId]);

  useEffect(() => {
    if (!!data && data.network) {
      syncToDb(data).finally(() => {
        setInitData(true);
      });
    }
  }, [data]);

  useEffect(() => {
    if (isAuthenticated && isInitData && !isCreateAccount) {
      syncAccount(userId!).finally(() => {
        setCreateAccount(true);
        doSyncAll();
      });
    }
  });

  return (
    <WalletContext.Provider
      value={{
        isSyncAll: isSyncAll,
        syncAll: doSyncAll,
        isLoading: !isInitData || !isCreateAccount || isSyncAll,
        currency,
        changeCurrency,
      }}>
      {children}
    </WalletContext.Provider>
  );
};
