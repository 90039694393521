import axios from 'axios';

export const createUser = async (userId: string, email: string) => {
  const endpoint = `${process.env.REACT_APP_STRAPI_URL}/waitlist-users`;
  const payload = {
    data: {
      paywongUserId: userId,
      email,
    },
  };
  const request = await axios.post(endpoint, payload);

  return request.data;
};

export const getUserById = async (userId: string) => {
  const endpoint = `${process.env.REACT_APP_STRAPI_URL}/waitlist-users?filters[paywongUserId][$eq]=${userId}`;

  const request = await axios.get(endpoint);

  return request.data;
};

export const validateReferralCode = async (referralCode: string) => {
  const endpoint = `${process.env.REACT_APP_STRAPI_URL}/waitlist-users?filters[referralCode][$eq]=${referralCode}`;

  const request = await axios.get(endpoint);

  return request.data;
};

export const updateUser = async (strapiUserId: number | string, payload: any) => {
  const endpoint = `${process.env.REACT_APP_STRAPI_URL}/waitlist-users/${strapiUserId}`;

  const request = await axios.put(endpoint, payload);

  return request.data;
};

export const getRefferedUsers = async (userId: string) => {
  const endpoint = `${process.env.REACT_APP_STRAPI_URL}/waitlist-users?filters[referrer][$eq]=${userId}`;

  const request = await axios.get(endpoint);

  return request.data;
};
