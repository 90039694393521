import { createContext, useState, useCallback, useMemo, ReactNode } from 'react';
import { useMagic } from 'hooks';
import { MagicUserMetadata } from 'magic-sdk';
import * as TokenStorage from 'utils/TokenStorage';
import { Provider } from 'urql';

import { makeClient } from 'client/configure';
import { NETWORK } from 'chains';

interface AuthContextType {
  isAuthenticated: boolean;
  isInitializing: boolean;
  userMetadata?: MagicUserMetadata;
  setUserMetadata: (userMetadata?: MagicUserMetadata) => void;
  setToken: (token?: string) => void;
  loginWithEmail: (email: string) => Promise<string | null>;
  logout: () => Promise<void>;
  setIsInitializing: (isInitializing: boolean) => void;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isInitializing: false,
  loginWithEmail: async () => null,
  logout: async () => {},
  setToken: () => {},
  setUserMetadata: () => {},
  setIsInitializing: () => {},
});

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isInitializing, setIsInitializing] = useState(true);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [userMetadata, setUserMetadata] = useState<MagicUserMetadata | undefined>(undefined);
  const { magic } = useMagic(NETWORK.ethereum);

  const loginWithEmail = useCallback(
    async (email: string, redirectURI?: string) => {
      if (userMetadata) {
        if (userMetadata.email === email) {
          const idToken = await magic.user.getIdToken();
          return idToken;
        } else {
          await magic.user.logout();
        }
      }
      const didToken = await magic.auth.loginWithMagicLink({
        email,
        redirectURI,
      });
      const newUserMetadata = await magic.user.getMetadata();
      setUserMetadata(newUserMetadata);
      return didToken;
    },
    [magic.auth, magic.user, userMetadata]
  );

  const setCustomToken = useCallback((token?: string) => {
    console.log('getting token: ', token);
    if (token) {
      TokenStorage.saveToken(token);
      setToken(token);
    } else {
      console.log('removing token');
      TokenStorage.removeToken();
      setToken(undefined);
    }
  }, []);

  const logout = useCallback(async () => {
    await magic.user.logout();
    setCustomToken(undefined);
  }, [magic.user, setCustomToken]);

  const client = useMemo(() => {
    return makeClient(logout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMetadata]);

  const isAuthenticated = useMemo(() => !!token, [token]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        isInitializing,
        userMetadata,
        loginWithEmail,
        logout,
        setToken: setCustomToken,
        setUserMetadata,
        setIsInitializing,
      }}>
      {/* <Provider value={client}>{children}</Provider> */}
      <Provider value={client}>{children}</Provider>
    </AuthContext.Provider>
  );
};
