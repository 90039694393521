import { Colors } from '@chakra-ui/react';

const colors: Colors = {
  primary: {
    50: '#F5F5FF',
    100: '#E1E1FE',
    200: '#C3C4FE',
    300: '#9B9DFD',
    400: '#7375FD',
    500: '#4B4EFC',
    600: '#1E22FB',
    700: '#0408E7',
    800: '#0306BA',
    900: '#02058D',
  },
  green: {
    50: '#a9f0c3',
    100: '#7ee9a5',
    200: '#68e596',
    300: '#52e187',
    400: '#3ddd78',
    500: '#22C55E',
    600: '#1a9a49',
    700: '#17843f',
    800: '#136e34',
    900: '#0b4320',
  },
  orange: {
    50: '#fce2b6',
    100: '#facf85',
    200: '#f9c56d',
    300: '#f8bb54',
    400: '#f7b13c',
    500: '#F59E0B',
    600: '#c57f08',
    700: '#ac6f07',
    800: '#945f06',
    900: '#634004',
  },
  red: {
    50: '#fde8e8',
    100: '#f9b9b9',
    200: '#f7a2a2',
    300: '#f58a8a',
    400: '#f37373',
    500: '#EF4444',
    600: '#eb1515',
    700: '#d41212',
    800: '#bd1010',
    900: '#8e0c0c',
  },
  gray: {
    50: '#F9FAFB',
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },
  destructive: {
    50: '#FEF2F2',
    100: '#FEE2E2',
    200: '#FECACA',
    300: '#FCA5A5',
    400: '#F87171',
    500: '#EF4444',
    600: '#DC2626',
    700: '#B91C1C',
    800: '#7F1D1D',
    900: '#7F1D1D',
  },
  success: {
    50: '#F0FDF4',
    100: '#DCFCE7',
    200: '#BBF7D0',
    300: '#86EFAC',
    400: '#4ADE80',
    500: '#22C55E',
    600: '#16A34A',
    700: '#15803D',
    800: '#166534',
    900: '#14532D',
  },
};

export default colors;
