import { Box, BoxProps, Flex, Stack, VStack, Center, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import NavItem from './NavItem';
import navs from 'constants/navs';

import Logo from '../../Logo';
import NavGroup from './NavGroup';

interface SidebarProps extends BoxProps {}

const Sidebar = ({ ...rest }: SidebarProps) => {
  const navigate = useNavigate();
  const borderColor = useColorModeValue('gray.100', 'gray.800');

  return (
    <Box width="240px" minW="240px" {...rest} borderRightColor={borderColor} borderRightWidth={1}>
      <Flex direction="column" w="240px" height="100vh" position="fixed" pt="8" overflowY="auto">
        <Stack px="3" spacing="8" as="nav" aria-label="Sidebar Navigation" w={'100%'}>
          <Center cursor="pointer" onClick={() => navigate('/')} py="1">
            <Logo h="6" />
          </Center>

          <VStack justify={'flex-start'} alignItems="flex-start" spacing={'4'}>
            {navs.map((item, index) => {
              const isGroup = !!item.childs;
              const key = `navroot-${index}-${item.pathname}`;
              if (!isGroup) {
                return <NavItem key={key} {...item} />;
              } else {
                return <NavGroup key={key} {...item} />;
              }
            })}
          </VStack>
        </Stack>
      </Flex>
    </Box>
  );
};

export default Sidebar;
