import { Flex } from '@chakra-ui/react';
import { useAuth } from 'hooks';

import { Navigate, Outlet } from 'react-router-dom';
import { ColorMix } from 'theme/types';

interface FullScreenLayoutProps {
  isCheckLogin?: boolean;
}

const FullScreenLayout = ({ isCheckLogin }: FullScreenLayoutProps) => {
  const { isAuthenticated } = useAuth();

  if (isCheckLogin && !isAuthenticated) {
    return <Navigate to={'/waitlist'} />;
  }

  return (
    <Flex minH="100vh" flex="1" flexDirection="column" bg={ColorMix.bgContent}>
      <Outlet />
    </Flex>
  );
};

export default FullScreenLayout;
