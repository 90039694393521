import { NavProps } from 'components/layouts/SidebarLayout/types';
import {
  HiOutlineEye,
  HiOutlineCurrencyDollar,
  HiOutlineDocument,
  HiOutlineClipboardList,
  HiOutlineUsers,
  HiOutlineUser,
  HiOutlineInformationCircle,
  HiOutlineCog,
  HiUserGroup,
} from 'react-icons/hi';

// const navs: Array<NavProps> = [
//   {
//     label: 'overview',
//     icon: HiOutlineEye,
//     pathname: '/',
//   },
//   {
//     label: 'balances',
//     icon: HiOutlineCurrencyDollar,

//     childs: [
//       {
//         label: 'my-balances',
//         pathname: '/balances',
//       },
//       {
//         label: 'transactions',
//         pathname: '/transactions',
//       },
//       {
//         label: 'swap',
//         pathname: '/swap',
//       },
//       {
//         label: 'bridge',
//         pathname: '/bridge',
//       },
//     ],
//   },
//   {
//     label: 'invoices',
//     icon: HiOutlineDocument,
//     childs: [
//       {
//         label: 'create-invoice',
//         pathname: '/invoice/new',
//       },
//       // {
//       //   label: 'shared-with-me',
//       //   pathname: '/invoices/shared',
//       // },
//       {
//         label: 'draft',
//         pathname: '/invoices/draft',
//       },
//       {
//         label: 'sent',
//         pathname: '/invoices/sent',
//       },
//       {
//         label: 'received',
//         pathname: '/invoices/received',
//       },
//     ],
//   },
//   {
//     label: 'payments',
//     icon: HiOutlineClipboardList,
//     childs: [
//       {
//         label: 'pending',
//       },
//       {
//         label: 'paid',
//       },
//     ],
//   },
//   {
//     label: 'contacts',
//     icon: HiOutlineUsers,
//     childs: [
//       {
//         label: 'customers',
//         pathname: 'contacts/customers',
//       },
//       {
//         label: 'employees',
//         pathname: 'contacts/employees',
//       },
//     ],
//   },
//   {
//     label: 'account',
//     icon: HiOutlineUser,
//     pathname: '/account',
//   },
//   {
//     label: 'settings',
//     icon: HiOutlineCog,
//     pathname: '/settings',
//   },
//   {
//     label: 'documentation',
//     icon: HiOutlineInformationCircle,
//     pathname: 'https://docs.paywong.com',
//   },
// ];

const navs: Array<NavProps> = [
  {
    label: 'referral',
    icon: HiUserGroup,
    pathname: '/',
  },
];

export default navs;
