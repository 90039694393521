import { providerConfigs } from './configs';
import { NETWORK, ProviderConfig } from './types';
import { ethers } from 'ethers';

let configs: Record<NETWORK | string, ProviderConfig> = {
  ...providerConfigs,
};

export const setConfigs = (newConfigs: Record<NETWORK | string, ProviderConfig>) => {
  configs = { ...configs, ...newConfigs };
};

export const getConfig = (network: NETWORK) =>
  configs.hasOwnProperty(network) ? configs[network] : null;

export const getProvider = (network: NETWORK) => {
  const config = getConfig(network);
  if (!config) {
    return null;
  }
  return new ethers.providers.JsonRpcProvider({
    url: config.rpcUrl,
    allowGzip: true,
  });
};
