import { Collapse, Flex, HStack, Icon, Text, useDisclosure } from '@chakra-ui/react';
import { memo, useMemo } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';
import isEqual from 'react-fast-compare';
import { TextStyle } from 'theme/types';
import NavItem from './NavItem';
import { NavProps } from './types';
import { useTranslation } from 'react-i18next';

const NavGroup = ({ label, icon, childs }: NavProps) => {
  const location = useLocation();
  const { t } = useTranslation('nav');
  const isActiveChild = useMemo(
    () => (childs ? childs.findIndex((i) => location.pathname === i.pathname) >= 0 : false),
    [childs, location.pathname]
  );
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: isActiveChild });
  return (
    <Flex flexDirection={'column'} w={'full'}>
      <Flex
        color="nav"
        borderRadius="md"
        w={'full'}
        justify="space-between"
        _hover={{
          bg: 'primary.50',
          color: 'primary.500',
        }}
        _activeLink={{
          bg: 'primary.50',
          color: 'primary.500',
        }}
        px="12px"
        py="8px"
        aria-current={isActiveChild ? 'page' : undefined}
        onClick={onToggle}
        cursor="pointer">
        <HStack spacing="2">
          {icon ? <Icon as={icon} fontSize="lg" /> : null}
          <Text
            textStyle={TextStyle.ParagraphSmall}
            ml={icon ? undefined : '26px'}
            fontWeight="medium"
            w="full">
            {t(label)}
          </Text>
        </HStack>
        <Icon as={isOpen ? HiChevronUp : HiChevronDown} fontSize="lg" />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        {childs?.map((item, index) => (
          <NavItem key={`nav-${index}-${item.pathname}`} {...item} />
        ))}
      </Collapse>
    </Flex>
  );
};

export default memo(NavGroup, isEqual);
