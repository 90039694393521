import { Navigate, useRoutes } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import { lazy, ReactNode, Suspense } from 'react';
import { Center, Spinner } from '@chakra-ui/react';

import SidebarLayout from 'components/layouts/SidebarLayout';
import PageNotFoundView from './PageNotFoundView';
import { useAuth } from 'hooks';
import Splash from './Splash';
import FullScreenLayout from 'components/layouts/FullscreenLayout';

type RouteLoaderProps = {
  children: ReactNode;
};

const Waitlist = lazy(() => import('./Waitlist'));
const Referral = lazy(() => import('./Referral'));

const RouteLoader = ({ children }: RouteLoaderProps) => {
  return (
    <Suspense
      fallback={
        <Center w="100%" h="100%">
          <Spinner />
        </Center>
      }>
      {children}
    </Suspense>
  );
};

const Routers = () => {
  const { isInitializing } = useAuth();
  const mainRoutes: RouteObject = {
    path: '/',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: (
          <RouteLoader>
            <Referral />
          </RouteLoader>
        ),
      },
    ],
  };

  const authRoutes: RouteObject = {
    path: '/waitlist',
    element: <FullScreenLayout />,
    children: [
      { path: '*', element: <Navigate to="/404" /> },
      {
        path: '/waitlist',
        element: (
          <RouteLoader>
            <Waitlist />
          </RouteLoader>
        ),
      },
      {
        path: ':referralCode',
        element: (
          <RouteLoader>
            <Waitlist />
          </RouteLoader>
        ),
      },
    ],
  };
  const NotFoundRoutes: RouteObject = {
    path: '404',
    element: <PageNotFoundView />,
  };

  const routing = useRoutes([NotFoundRoutes, mainRoutes, authRoutes]);
  if (isInitializing) {
    return <Splash />;
  }

  return <>{routing}</>;
};

export default Routers;
