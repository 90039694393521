import '@fontsource/inter';
import { ChakraProvider } from '@chakra-ui/react';
import { customTheme } from 'theme';
import Routers from 'pages/Routers';
import { AuthProvider } from './AuthContext';
import { WalletProvider } from './wallet/Context';
import 'i18n/config';

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <AuthProvider>
        <WalletProvider>
          <Routers />
        </WalletProvider>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
